import React, { useState, useEffect } from "react";
import ResponsiveAppBar from "../../Components/header.tsx";
import Box from "@mui/material/Box";
import img1 from "../../Assets/visitor.jpg";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Footer from "../../Components/Footer.tsx";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Link from "@mui/material/Link";
import axios from "axios";
import { VISITOR_URL } from "../../APIendpoints.js";



const useStyles = makeStyles((theme) => ({
  gradientPaper: {
    //   background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    backgroundImage:
      "linear-gradient(to right top, #485555, #596468, #6e737a, #838289, #989297, #a6949d, #b6959e, #c5969a, #d1878b, #db777a, #e36668, #e95353)",
    padding: theme.spacing(2),
    [theme.breakpoints.only("xs")]: {
      zoom: "85%", // adjust zoom for xs only
    },
  },
  gridContainerButtons: {
    
    [theme.breakpoints.only('xs')]: {
        zoom: '80%', // adjust zoom for xs only
      },
  },
  table: {
    minWidth: 650,
    "& tbody tr:hover": {
      backgroundColor: "#ffa7a7",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name:string, makemodel: string, vin: string, registeredon: string) {
  return { name, makemodel, vin, registeredon };
}



const RegisterVehicle = () => {
  const classes = useStyles();
  const [account, setAccount] = React.useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [contact, setContact] = useState("");
  const [address, setAddress] = useState("")
  const [rows, setRows] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  useEffect(() => {
    let role = ["temp"];
    role[0] = localStorage.getItem('userRole');
    if(role[0] === "resident"){
      window.location.href = '/resident';
    }else if(role[0] === "visitor"){
      console.log("Hii")
    }else if(role[0] === "buildingmanager"){
      window.location.href = '/manager/building';
    }else if(role[0] === "poolmanager"){
      window.location.href = '/manager/pool';
    }else if(role[0] === "gardenmanager"){
      window.location.href = '/manager/garden';
    }else if(role[0] === "securitymanager"){
      window.location.href = '/manager/security';
    }else{
      localStorage.clear();
    }

    axios
      .post(VISITOR_URL, {
        request_type: "get visitor data",
        userid: localStorage.getItem("userId"),
        userrole: localStorage.getItem("userRole"),
      })
      .then(function (response) {
        console.log(response.data.status)
        response.data.status === "success"
          ? handleSuccess(response.data)
          : handleFailure(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });    

  }, []);


  const handleFailure = (response) => {
    // setErrorMessage(response.data.error);
    // setOpen(true)
    // setPassword("");
    console.log("Failed")
  };

  const handleSuccess = (response) => {
    const responsedata = response.register_details[0]
   console.log(responsedata)
    setName(responsedata.name)
    setPassword(responsedata.password)
    setContact(responsedata.contact)
    setAddress(responsedata.address)
  };
  
  

  const handleChange = (event: SelectChangeEvent) => {
    setAccount(event.target.value as string);
  };

  const validatePassword = (password) => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    return re.test(password);
  };

  const validateContact = (contact) => {
    const re = /^[0-9]{10}$/i;
    return re.test(contact);
  };

  const validateAddress = (address) => {
    const myArray = address.split(",");
    const validPart = myArray.filter(part => part !== null && part.length > 0);
    const invalidPart = myArray.filter(part => !(part !== null && part.length > 0));
    return validPart.length >= 3 && invalidPart.length == 0

  };


  const validateName = (name) => {
    return name.length >= 3
  };

  

  const abcd = (event) => {
    event.preventDefault();
    console.log(VISITOR_URL)
    axios
      .post(VISITOR_URL, {
        request_type: "update visitor details",
        password: password,
        address: address,
        contact: contact,
        name: name,
        userid: localStorage.getItem("userId"),
        userrole: localStorage.getItem("userRole"),
      })
      .then(function (response) {
        console.log(response)
        if(response.data.status == "success"){
            alert('Successfully updated details')
        }
        // setRows([...rows, createData(makemodel , vin, response.data.registeredon)])
        // setMakemodel('');
        // setVin('');
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  return (
    <div className="home bgimg">
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
        }}
      >
        <ResponsiveAppBar />
      </Box>
      <Box
        class="candles"
        style={{
          backgroundImage: `url(${img1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          color: "#f5f5f5",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          component="span"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            //   backgroundColor: 'rgba(0,0,0,0.4)',
          }}
        />

        <Box
          sx={{
            position: "relative",
            zIndex: 2,
            width: "100%",
            height: "100%",
          }}
        >
          <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
            <Grid container sx={{ m: 2 }} className={classes.gridContainerButtons}>
              <Grid item xs={5} md={3} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() => (window.location.href = "/visitor/regveh")}
                >
                  Register Vehicles
                </Button>
              </Grid>

              <Grid item xs={5} md={3.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/visitor/drivinginstructions")
                  }
                >
                  Driving Instructions
                </Button>
              </Grid>

              <Grid item xs={5} md={2} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() => (window.location.href = "/visitor/timings")}
                >
                  Timings
                </Button>
              </Grid>

              <Grid item xs={5} md={2} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() => (window.location.href = "/visitor/chat")}
                >
                  Chat
                </Button>
              </Grid>
              <Grid item xs={5} md={2} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() => (window.location.href = "/visitor/visitor/details")}
                >
                  Profile
                </Button>
              </Grid>
            </Grid>

            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
              className={classes.gradientPaper}
            >
              <Typography
                component="h3"
                variant="h5"
                align="center"
                sx={{
                  mb: 2,
                  fontSize: "1.5rem",
                  display: "block",
                  fontFamily: '"Montserrat", sans-serif',
                }}
              >
                <Box sx={{ display: "inline" }}> Personal</Box>
                <Box
                  component="span"
                  sx={{
                    color: "red",
                    marginLeft: "0.25rem",
                  }}
                >
                  Information
                </Box>
              </Typography>
              <Divider variant="middle" />

            
              <form onSubmit={abcd}>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      id="name"
                      name="name"
                      label="Name"
                      type="text"
                      color="error"
                      value={name}
                      onChange={(e)=>setName(e.target.value)}
                      error={name != "" && !validateName(name)}
                      helperText={
                        name != "" && !validateName(name)
                          ? "Please enter a valid name"
                          : ""
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      id="password"
                      name="password"
                      label="Password"
                      type="text"
                      color="error"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      error={password!="" && !validatePassword(password)}
                      helperText={password!="" && !validatePassword(password) ? 'Password should contain at least one uppercase letter, one lowercase letter, one number, and be at least 8 characters long' : ''}
                    
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      id="contact"
                      name="contact"
                      label={"Contact"}
                      type="text"
                      color="error"
                      value={contact}
                      onChange={(e) => setContact(e.target.value)}
                      error={contact != "" && !validateContact(contact)}
                      helperText={
                        contact != "" && !validateContact(contact)
                          ? "Please enter a valid Contact"
                          : ""
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      id="address"
                      name="address"
                      label="address"
                      type="text"
                      color="error"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      error={address != "" && !validateAddress(address)}
                      helperText={
                        address != "" && !validateAddress(address)
                          ? "Please enter a valid  address: Street, City, State"
                          : ""
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    justifyContent="center"
                    sx={{ mb: 2 }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="error"
                      className={classes.submitButton}
                      disabled={ !validateAddress(address) || !validateContact(contact) || !validateName(name) || !validatePassword(password)} 

                    >
                      Update
                    </Button>
                  </Grid>
                </Grid>
              </form>

              

            </Paper>
          </Container>
        </Box>
      </Box>
      <Footer />
    </div>
  );
};

export default RegisterVehicle;
