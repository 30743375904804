import React, { useState, useEffect } from "react";

const ChatBar = ({ socket }) => {
  const [users, setUsers] = useState([]);
  const handleUserResponse=(d) => {
    console.log(d)
    setUsers(d)
  }

  useEffect(() => {
    console.log(users)
    socket.on("newUserResponse", (data) => handleUserResponse(data));
  }, [socket, users]);

  return (
    <div className="chat__sidebar">
      <h2>Connected</h2>
      <div>
        <h4 className="chat__header">Connected user</h4>
        <div className="chat__users">
          {users.map((user) => (
            <p key={user.socketID}>{user.userName}</p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChatBar;
