import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ResponsiveAppBar from "../../../Components/header.tsx";


const Home = ({ socket }) => {
  const navigate = useNavigate();
  const [userName, setUserName] = React.useState(localStorage.getItem('userId'));

  useEffect(() => {
    let role = ["temp"];
    role[0] = localStorage.getItem('userRole');
    if(role[0] === "visitor"){
      window.location.href = '/visitor';
    }else if(role[0] === "resident"){
      window.location.href = '/resident';
    }else if(role[0] === "buildingmanager"){
      window.location.href = '/manager/building';
    }else if(role[0] === "poolmanager"){
      window.location.href = '/manager/pool';
    }else if(role[0] === "gardenmanager"){
      window.location.href = '/manager/garden';
    }else if(role[0] === "securitymanager"){
      console.log("Hii")

      
    }else{
      localStorage.clear();
    }
  
    
  }, [])
  

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem("userName", userName);
    console.log(socket.id);
    socket.emit("newUser", { userName, socketID: userName });
    navigate("/manager/security/chatbody");
  };
  return (
    <>
    <ResponsiveAppBar/>
    <form className="home__container" onSubmit={handleSubmit}>
      <h2 className="home__header">Who do you want to chat with?</h2>
      
      {/* <input
        type="text"
        minLength={6}
        name="username"
        id="username"
        className="username__input"
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
      /> */}
      <select name="cars" id="cars" className='username_input' style={{backgroundColor: '#f1f1f1', color: '#333', fontSize: '16px', width:'20%'}}>
  <option value="volvo">Building Manager</option>
  <option value="saab">Pool Manager</option>
  <option value="mercedes">Garden Manager</option>
  <option value="audi1">Visitor</option>
  <option value="audi2">Resident</option>
</select>
      <button className="home__cta">Chat now</button>
    </form>

    
    </>
  );
};

export default Home;
