import React from "react";
import ResponsiveAppBar from "../../Components/header.tsx";
import Box from "@mui/material/Box";
import img1 from "../../Assets/visitor.jpg";
import img2 from "../../Assets/map.jpeg";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Footer from "../../Components/Footer.tsx";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Link from "@mui/material/Link";

const useStyles = makeStyles((theme) => ({
  gradientPaper: {
    //   background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    backgroundImage:
      "linear-gradient(to right top, #485555, #596468, #6e737a, #838289, #989297, #a6949d, #b6959e, #c5969a, #d1878b, #db777a, #e36668, #e95353)",
    padding: theme.spacing(2),
    [theme.breakpoints.only("xs")]: {
      zoom: "65%", // adjust zoom for xs only
    },
  },
  gridContainerButtons: {
    
    [theme.breakpoints.only('xs')]: {
        zoom: '80%', // adjust zoom for xs only
      },
  },
  table: {
    minWidth: 650,
    "& tbody tr:hover": {
      backgroundColor: "#ffa7a7",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(what: string, where: string, when: string) {
  return { what, where, when };
}

const rows = [
  createData("Romancing under stars", "Pool 3", "8PM-10PM"),
  createData("Bake Sale", "Pool 5", "11AM-2PM"),
  createData("Badminton Match-ups", "Court 2", "7PM-9PM"),
  createData("Fun at Park", "Garden 3", "4PM-7PM"),
  createData("Get Together", "Garden 3", "5PM-8PM"),
];

const DrivingInstructions = () => {
  const classes = useStyles();
  const [account, setAccount] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setAccount(event.target.value as string);
  };
  return (
    <div className="home bgimg">
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
        }}
      >
        <ResponsiveAppBar />
      </Box>
      <Box
        class="candles"
        style={{
          backgroundImage: `url(${img1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          color: "#f5f5f5",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          component="span"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            //   backgroundColor: 'rgba(0,0,0,0.4)',
          }}
        />

        <Box
          sx={{
            position: "relative",
            zIndex: 2,
            width: "100%",
            height: "100%",
          }}
        >
          <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
            <Grid container sx={{ m: 2 }} className={classes.gridContainerButtons}>
              <Grid item xs={5} md={3} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() => (window.location.href = "/visitor/regveh")}
                >
                  Register Vehicles
                </Button>
              </Grid>

              <Grid item xs={5} md={3.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/visitor/drivinginstructions")
                  }
                >
                  Driving Instructions
                </Button>
              </Grid>

              <Grid item xs={5} md={2} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() => (window.location.href = "/visitor/timings")}
                >
                  Timings
                </Button>
              </Grid>

              <Grid item xs={5} md={2} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() => (window.location.href = "/visitor/chat")}
                >
                  Chat
                </Button>
              </Grid>
              <Grid item xs={5} md={2} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() => (window.location.href = "/visitor/visitor/details")}
                >
                  Profile
                </Button>
              </Grid>
            </Grid>

            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
              className={classes.gradientPaper}
            >
              <Typography
                component="h3"
                variant="h5"
                align="center"
                sx={{
                  mb: 2,
                  fontSize: "1.5rem",
                  display: "block",
                  fontFamily: '"Montserrat", sans-serif',
                }}
              >
                <Box sx={{ display: "inline" }}> Driving</Box>
                <Box
                  component="span"
                  sx={{
                    color: "red",
                    marginLeft: "0.25rem",
                  }}
                >
                  <Link href='https://google.com/maps/search/Centennial%20Court%20Apartments%20700%20W%20Mitchell%20Cir,%2076013,%20Arlington'>Map</Link>
                </Box>
              </Typography>
              <Divider variant="middle" />
              <img src={img2} style={{ maxWidth: '100%', maxHeight: '100%' }}   />

             

              
            </Paper>
          </Container>
        </Box>
      </Box>
      <Footer />
    </div>
  );
};

export default DrivingInstructions;
