import React, {useState, useEffect} from "react";
import ResponsiveAppBar from "../../../Components/header.tsx";
import Box from "@mui/material/Box";
import img1 from "../../../Assets/security.jpg";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Footer from "../../../Components/Footer.tsx";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Link from '@mui/material/Link';
import axios from "axios";
import { SECURITY_URL } from "../../../APIendpoints.js";

const useStyles = makeStyles((theme) => ({
  gradientPaper: {
    //   background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    backgroundImage:
      "linear-gradient(to right top, #485555, #596468, #6e737a, #838289, #989297, #a6949d, #b6959e, #c5969a, #d1878b, #db777a, #e36668, #e95353)",
    padding: theme.spacing(2),
    zoom:"90%",
    [theme.breakpoints.only('xs')]: {
      zoom: '80%', // adjust zoom for xs only
    },
  },
  gridContainerButtons: {
    [theme.breakpoints.only('xs')]: {
        zoom: '85%', // adjust zoom for xs only
      },
  },
  table: {
    minWidth: 650,
    "& tbody tr:hover": {
      backgroundColor: "#ffa7a7",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name:string, number:string, unit:string, address:string, id:string, poolaccess:string) {
  return { name, number, unit, address, id, poolaccess };
}

const Chat = () => {
  const classes = useStyles();
  const [account, setAccount] = React.useState('');
  const [rows, setRows] = useState([])

  const handleChange = (event: SelectChangeEvent) => {
    setAccount(event.target.value as string);
  };

  useEffect(() => {
    let role = ["temp"];
    role[0] = localStorage.getItem('userRole');
    if(role[0] === "visitor"){
      window.location.href = '/visitor';
    }else if(role[0] === "resident"){
      window.location.href = '/resident';
    }else if(role[0] === "buildingmanager"){
      window.location.href = '/manager/building';
    }else if(role[0] === "poolmanager"){
      window.location.href = '/manager/pool';
    }else if(role[0] === "gardenmanager"){
      window.location.href = '/manager/garden';
    }else if(role[0] === "securitymanager"){
      console.log('Hi')
    }else{
      localStorage.clear();
    }

    axios
    .post(SECURITY_URL, {
      request_type: "get visitor data",
      userid: localStorage.getItem("userId"),
      userrole: localStorage.getItem("userRole"),
    })
    .then(function (response) {
      console.log(response)
      response.data.status === "success"
        ? handleSuccess(response.data)
        : handleFailure(response.data);
    })
    .catch(function (error) {
      console.log(error);
    }); 


  }, []);

  const handleFailure = (response) => {
    // setErrorMessage(response.data.error);
    // setOpen(true)
    // setPassword("");
    console.log("Failed")
  };

  const handleSuccess = (response) => {
    const responsedata = response.activity_details
   
    const newRows = responsedata.map((data) =>
      createData(data.name ,data.number, data.visitingunitno, data.address, data.id, data.securityaccess)
    );
    setRows(newRows);
  };

  const changePoolAccess = (row) => {
    console.log(row)
    row.poolaccess = row.poolaccess == "YES" ? "NO" : "YES"
    axios
        .post(SECURITY_URL, {
          request_type: "change security access visitor",
          visitorid:row.id,
          poolaccess: row.poolaccess,
        })
        .then(function (response) {
          console.log(response)
          
          if (response.data.status == "success"){
            const newRows = rows.filter(r => r.id != row.id)

            setRows([...newRows, row])
            
          }else{
            alert("Error occured please try again")
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }


  return (
    <div className="home bgimg">
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
        }}
      >
        <ResponsiveAppBar />
      </Box>
      <Box
        class="candles"
        style={{
          backgroundImage: `url(${img1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          color: "#f5f5f5",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          component="span"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            //   backgroundColor: 'rgba(0,0,0,0.4)',
          }}
        />

        <Box
          sx={{
            position: "relative",
            zIndex: 2,
            width: "100%",
            height: "100%",
          }}
        >
          <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
          <Grid
              container
              sx={{ m: 2 }}
              className={classes.gridContainerButtons}
            >
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/manager/security/security")
                  }
                >
                  Manage Security
                </Button>
              </Grid>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/manager/security/timings")
                  }
                >
                  Manage Timings
                </Button>
              </Grid>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/manager/security/resident")
                  }
                >
                  Manage Resident
                </Button>
              </Grid>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/manager/security/visitor")
                  }
                >
                  Manage Visitor
                </Button>
              </Grid>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/manager/security/report")
                  }
                >
                  Reports
                </Button>
              </Grid>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/manager/security/chat")
                  }
                >
                  Chat
                </Button>
              </Grid>
            </Grid>


            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
              className={classes.gradientPaper}
            >
              <Typography
                component="h3"
                variant="h5"
                align="center"
                sx={{
                  mb: 2,
                  fontSize: "1.5rem",
                  display: "block",
                  fontFamily: '"Montserrat", sans-serif',
                }}
              >
                <Box sx={{ display: "inline" }}>
                  {" "}
                  Check
                </Box>
                <Box
                  component="span"
                  sx={{
                    color: "red",
                    marginLeft: "0.25rem",
                    
                  }}
                >
                  Visitor
                </Box>
              </Typography>
              <Divider variant="middle" />

              <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table
                  sx={{ minWidth: 700 }}
                  aria-label="customized table"
                  className={classes.table}
                > 
                  <TableHead>
                    <TableRow>
                      <StyledTableCell >Name</StyledTableCell>
                      <StyledTableCell align="center">Number</StyledTableCell>
                      <StyledTableCell align="center">Visiting Unit No</StyledTableCell>
                      <StyledTableCell align="left">Address</StyledTableCell>
                      <StyledTableCell align="center">Actions</StyledTableCell>
                      <StyledTableCell align="center">Delete</StyledTableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={row.name}>
                        <StyledTableCell component="th" scope="row">
                          {row.name}
                        </StyledTableCell>
                        
                        <StyledTableCell align="center">
                          {row.number}
                        </StyledTableCell>
                      
                        <StyledTableCell align="center">
                          {row.unit}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          {row.address}
                        </StyledTableCell>  


                        <StyledTableCell align="center">
                          {row.poolaccess}
                        </StyledTableCell>  

                        <StyledTableCell align="center">
                          <Link onClick={() => changePoolAccess(row)}>Change</Link>
                        </StyledTableCell>  

                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              

              
              
            </Paper>
          </Container>
        </Box>
      </Box>
      <Footer />
    </div>
  );
};

export default Chat;
