//const API_URL = 'http://localhost:8000';
const API_URL = '/SQL';
export const LOGIN_URL = `${API_URL}/login.php`;
export const REGISTER_URL = `${API_URL}/register.php`;
export const RESIDENT_URL = `${API_URL}/resident.php`;
export const VISITOR_URL = `${API_URL}/visitor.php`;
export const BUILDING_URL = `${API_URL}/building.php`;
export const POOL_URL = `${API_URL}/pool.php`;
export const GARDEN_URL = `${API_URL}/garden.php`;
export const SECURITY_URL = `${API_URL}/security.php`;
export const CHAT_URL = `${API_URL}/chat.php`;
export const FORGOT_URL = `${API_URL}/forgot.php`;








