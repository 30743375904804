import React, { useState, useEffect } from "react";
import ResponsiveAppBar from "../Components/header2.tsx";
import Box from "@mui/material/Box";
import img1 from "../Assets/img1.jpg";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Footer from "../Components/Footer.tsx";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { REGISTER_URL } from "../APIendpoints.js";
import axios from "axios";
import Link from "@mui/material/Link";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import emailjs from 'emailjs-com';


const useStyles = makeStyles((theme) => ({
  gradientPaper: {
    //   background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    backgroundImage:
      "linear-gradient(to right top, #485555, #596468, #6e737a, #838289, #989297, #a6949d, #b6959e, #c5969a, #d1878b, #db777a, #e36668, #e95353)",
    padding: theme.spacing(2),
  },
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[2],
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: theme.breakpoints.values.sm,
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
  customOutline: {
    borderColor: `#FF0000`,
    "& label.Mui-focused": {
      color: `#FF0000`,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: `#FF0000`,
    },
  },
}));

function sendEmail(email, name, role) {
 

  emailjs.sendForm('service_w5hrydr', 'template_5d6hw4h', this.form.current, 'hWylgXyZoZyDRSsZC')
    .then((result) => {
      console.log(result.text);
    }, (error) => {
      console.log(error.text);
    });
}

const Signup = () => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [contact, setContact] = useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [account, setAccount] = React.useState("");

  useEffect(() => {
    let role = ["temp"];
    role[0] = localStorage.getItem('userRole');
    if(role[0] === "visitor"){
      window.location.href = '/visitor';
    }else if(role[0] === "resident"){
      window.location.href = '/resident';
    }else if(role[0] === "buildingmanager"){
      window.location.href = '/manager/building';
    }else if(role[0] === "poolmanager"){
      window.location.href = '/manager/pool';
    }else if(role[0] === "gardenmanager"){
      window.location.href = '/manager/garden';
    }else if(role[0] === "securitymanager"){
      window.location.href = '/manager/security';
    }else{
      localStorage.clear();
    }
  }, []);

  const validatePassword = (password) => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    return re.test(password);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateName = (name) => {
    return name.length >= 3
  };

  const validateAddress = (address) => {
    const myArray = address.split(",");
    const validPart = myArray.filter(part => part !== null && part.length > 0);
    const invalidPart = myArray.filter(part => !(part !== null && part.length > 0));
    return validPart.length >= 3 && invalidPart.length == 0

  };

  const validateContact = (contact) => {
    const re = /^[0-9]{10}$/i;
    return re.test(contact);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setAccount(event.target.value as string);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .post(REGISTER_URL, {
        email: email,
        password: password,
        name: name,
        address: address,
        contact: contact,
        role: account,
      })
      .then((response) => {
        if (response.data.status === "success") {
          
          emailjs.sendForm('service_w5hrydr', 'template_5d6hw4h', '#myForm', 'hWylgXyZoZyDRSsZC')
          .then((result) => {
            console.log(result.text);
          }, (error) => {
            console.log(error.text);
          });


          window.location.href = "/login";
        } else {
          alert(response.data.message);
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className="home bgimg">
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
        }}
      >
        <ResponsiveAppBar />
      </Box>
      <Box
        class="candles"
        style={{
          backgroundImage: `url(${img1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          color: "#f5f5f5",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          component="span"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            //   backgroundColor: 'rgba(0,0,0,0.4)',
          }}
        />
        <Box
          sx={{
            position: "relative",
            zIndex: 2,
            width: "100%",
            height: "100%",
          }}
        >
          <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
              className={classes.gradientPaper}
            >
              <Typography
                component="h3"
                variant="h5"
                align="center"
                sx={{
                  mb: 2,
                  fontSize: "1.5rem",
                  display: "block",
                  fontFamily: '"Montserrat", sans-serif',
                }}
              >
                <Box sx={{ display: "inline" }}> Signup up! to check more </Box>
                <Box
                  component="span"
                  sx={{
                    color: "red",
                    marginLeft: "0.25rem",
                  }}
                >
                  exciting offers!
                </Box>
              </Typography>
              <Divider variant="middle" />

              <form id="myForm" style={{display:"none"}}>
                <div class="field">
                  <label for="from_name">from_name</label>
                  <input type="text" name="from_name" value="Terrazas De Guacuco" id="from_name"/>
                </div>
                <div class="field">
                  <label for="to_name">to_name</label>
                  <input type="text" name="to_name" value={email} id="to_name"/>
                </div>
                <div class="field">
                  <label for="message">message</label>
                  <input type="text" name="message" value="Account Created Successfully" id="message"/>
                </div>
                <div class="field">
                  <label for="reply_to">reply_to</label>
                  <input type="text" name="reply_to" value="terrazasdeguacuco24@gmail.com" id="reply_to"/>
                </div>

                <input type="submit" id="button" value="Send Email"/>
              </form>

              <form onSubmit={handleSubmit} >
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      id="name"
                      name="name"
                      color="error"
                      label="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      error={name != "" && !validateName(name)}
                      helperText={
                        name != "" && !validateName(name)
                          ? "Please enter a valid name"
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      id="to_name"
                      name="to_name"
                      label="Email"
                      type="email"
                      color="error"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      error={email != "" && !validateEmail(email)}
                      helperText={
                        email != "" && !validateEmail(email)
                          ? "Please enter a valid email address"
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      id="address"
                      name="address"
                      label="Address"
                      type="text"
                      color="error"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      error={address != "" && !validateAddress(email)}
                      helperText={
                        address != "" && !validateAddress(address)
                          ? "Please enter a valid  address: Street, City, State"
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      id="contact"
                      color="error"
                      name="contact"
                      label="Contact"
                      value={contact}
                      onChange={(e) => setContact(e.target.value)}
                      error={contact != "" && !validateContact(contact)}
                      helperText={
                        contact != "" && !validateContact(contact)
                          ? "Please enter a valid contact no"
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      id="password"
                      name="password"
                      label="Password"
                      type="password"
                      color="error"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      error={password!="" && !validatePassword(password)}
                      helperText={password!="" && !validatePassword(password) ? 'Password should contain at least one uppercase letter, one lowercase letter, one number, and be at least 8 characters long' : ''}
                    
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel color="error" id="demo-simple-select-label">
                        Account Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={account}
                        label="Age"
                        color="error"
                        onChange={handleChange}
                      >
                        <MenuItem value={"resident"}>Resident</MenuItem>
                        <MenuItem value={"visitor"}>Visitor</MenuItem>
                        <MenuItem value={"buildingmanager"}>
                          Building Manager
                        </MenuItem>
                        <MenuItem value={"gardenmanager"}>
                          Garden Manager
                        </MenuItem>
                        <MenuItem value={"poolmanager"}>pool Manager</MenuItem>
                        <MenuItem value={"securitymanager"}>
                          Security Manager
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} container justifyContent="center">
                    <Button
                      type="submit"
                      variant="contained"
                      color="error"
                      className={classes.submitButton}
                      disabled={!validateEmail(email) || !validateAddress(address) || !validateContact(contact) || !validateName(name) || !validatePassword(password)} 
                    >
                      Submit
                    </Button>
                  </Grid>
                  <Grid item xs={12} container justifyContent="center">
                    <Link href="/login" color="primary.dark">
                      Already a member? Login
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Container>
        </Box>
      </Box>
      <Footer />
    </div>
  );
};

export default Signup;
