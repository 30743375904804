import React from 'react'
import ResponsiveAppBar from '../Components/header2.tsx'
import Box from '@mui/material/Box';
import img1 from '../Assets/img1.jpg';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Footer from '../Components/Footer.tsx';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@material-ui/core';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles((theme) => ({
    gradientPaper: {
    //   background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
      backgroundImage: 'linear-gradient(to right top, #485555, #596468, #6e737a, #838289, #989297, #a6949d, #b6959e, #c5969a, #d1878b, #db777a, #e36668, #e95353)',
      padding: theme.spacing(2),
    },
  }));

const About = () => {
    const classes = useStyles();
  return (
    <div className="home bgimg">
         <Box
        sx={{
          position: 'relative',
          zIndex: 2,
        }}
      >
        <ResponsiveAppBar/>
        </Box>
        <Box
            class="candles"
            style={{
            backgroundImage: `url(${img1})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100vh",
            color: "#f5f5f5",
            display: 'flex',
            alignItems: 'center',
        }}>
             <Box
        component="span"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        //   backgroundColor: 'rgba(0,0,0,0.4)',
        }}
      />
       <Box
        sx={{
          position: 'relative',
          zIndex: 2,
          width: '100%',
            height: '100%',
        }}
      >
            <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }} className={classes.gradientPaper}>
          <Typography component="h3" variant="h5" align="center" sx={{mb:2, fontSize:'1.5rem', display:'block',fontFamily:'"Montserrat", sans-serif'}}>
           
            <Box sx={{ display: 'inline' }}> Live in with glory at</Box>
            <Box component="span" sx={{ color: 'red', marginLeft: '0.25rem' , textTransform:'uppercase' }}>Terrazas de Guacuco</Box>
          </Typography>
          <Divider variant="middle" />
          <p>Terrazas de Guacuco is a luxurious housing complex located in the heart of a beautiful coastal city. With 367 residential units, it offers a wide range of living options, including Studios, Town Houses, and Cabins. Each unit is designed with comfort, style and functionality in mind, making it a perfect place for everyone to call home. As you enter the complex, you will be greeted by lush gardens and pristine green lawns. The landscaping is breathtaking and the gardens are well maintained, thanks to the efforts of the garden manager. In these tranquil surroundings, you can relax, take a walk or simply enjoy the serenity of nature. The complex also has several walking trails, providing an excellent opportunity for outdoor enthusiasts to get some exercise and fresh air. For those who love to swim, Terrazas de Guacuco has two magnificent swimming pools. The pools are well maintained by the pool manager and are open to residents and visitors all year round. Whether you want to cool off on a hot summer day, take a swim with your family, or simply relax by the pool, the complex has everything you need</p>
          </Paper>
          </Container>
           </Box>
        </Box>
        <Footer/>
    </div>
  )
}

export default About

