import React, {useEffect, useState} from "react";
import ResponsiveAppBar from "../../../Components/header.tsx";
import Box from "@mui/material/Box";
import img1 from "../../../Assets/building.jpg";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Footer from "../../../Components/Footer.tsx";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Link from '@mui/material/Link';
import axios from "axios";
import { BUILDING_URL } from "../../../APIendpoints.js";

const useStyles = makeStyles((theme) => ({
  gradientPaper: {
    //   background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    backgroundImage:
      "linear-gradient(to right top, #485555, #596468, #6e737a, #838289, #989297, #a6949d, #b6959e, #c5969a, #d1878b, #db777a, #e36668, #e95353)",
    padding: theme.spacing(2),
    zoom:"80%",
    [theme.breakpoints.only('xs')]: {
      zoom: '85%', // adjust zoom for xs only
    },
  },
  gridContainerButtons: {
    zoom:"80%",
    [theme.breakpoints.only('xs')]: {
        zoom: '80S%', // adjust zoom for xs only
      },
  },
  table: {
    minWidth: 650,
    "& tbody tr:hover": {
      backgroundColor: "#ffa7a7",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(from:string, last:string, action:string) {
  return { from, last, action };
}

const rows = [
  createData("Building Admin", "02/25/2023", "<a href=''>Leave</a>"),
  createData("Pool Admin", "02/18/2023", "<a href=''>Leave</a>"),
  createData("Garden Admin", "02/21/2023", "<a href=''>Leave</a>"),
  
];

const Chat = () => {
  const classes = useStyles();
  const [account, setAccount] = React.useState('');

  const [pin, setPin] = useState("");

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [contact, setContact] = useState("");

  const validateName = (name) => {
    return name.length >= 3
  };

  const validateAddress = (address) => {
    const myArray = address.split(",");
    const validPart = myArray.filter(part => part !== null && part.length > 0);
    const invalidPart = myArray.filter(part => !(part !== null && part.length > 0));
    return validPart.length >= 3 && invalidPart.length == 0

  };

  useEffect(() => {
    let role = ["temp"];
    role[0] = localStorage.getItem('userRole');
    if(role[0] === "visitor"){
      window.location.href = '/visitor';
    }else if(role[0] === "resident"){
      window.location.href = '/resident';
    }else if(role[0] === "buildingmanager"){
      console.log("Hi")
    }else if(role[0] === "poolmanager"){
      
      window.location.href = '/manager/pool';
    }else if(role[0] === "gardenmanager"){
      window.location.href = '/manager/garden';
    }else if(role[0] === "securitymanager"){
      window.location.href = '/manager/security';
    }else{
      localStorage.clear();
    }
  }, [])

  const validateContact = (contact) => {
    const re = /^[0-9]{10}$/i;
    return re.test(contact);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setAccount(event.target.value as string);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .post(BUILDING_URL, {
        request_type: "add manager",
        userid: localStorage.getItem("userId"),
        userrole: localStorage.getItem("userRole"),
        name: name,
        address: address,
        contact: contact,
        pin: pin,
        role: "poolmanager",
      })
      .then((response) => {
        if (response.data.status === "success") {
          
         alert("Manager added successfully")
         setName("")
         setContact("")
         setAddress("")
         setPin("")
        } else {
          alert(response.data.message);
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className="home bgimg">
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
        }}
      >
        <ResponsiveAppBar />
      </Box>
      <Box
        class="candles"
        style={{
          backgroundImage: `url(${img1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          color: "#f5f5f5",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          component="span"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            //   backgroundColor: 'rgba(0,0,0,0.4)',
          }}
        />

        <Box
          sx={{
            position: "relative",
            zIndex: 2,
            width: "100%",
            height: "100%",
          }}
        >
          <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
            <Grid container sx={{ m: 2 }} className={classes.gridContainerButtons}>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() => (window.location.href = "/manager/building/managesecurity")}
                  
                >
                  Manage Securiy
                </Button>
              </Grid>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/manager/building/pool")
                  }
                >
                  Manage Pool
                </Button>
              </Grid>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/manager/building/garden")
                  }
                >
                  Manage Garden
                </Button>
              </Grid>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/manager/building/visitor")
                  }
                >
                  Manage Visitor
                </Button>
              </Grid>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/manager/building/resident")
                  }
                >
                  Manage Resident
                </Button>
              </Grid>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() => (window.location.href = "/manager/building/report")}
                >
                  Report
                </Button>
              </Grid>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() => (window.location.href = "/manager/building/chat")}
                >
                  Chat
                </Button>
              </Grid>
            </Grid>

            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
              className={classes.gradientPaper}
            >
              <Typography
                component="h3"
                variant="h5"
                align="center"
                sx={{
                  mb: 2,
                  fontSize: "1.5rem",
                  display: "block",
                  fontFamily: '"Montserrat", sans-serif',
                }}
              >
                <Box sx={{ display: "inline" }}>
                  {" "}
                  Manage
                </Box>
                <Box
                  component="span"
                  sx={{
                    color: "red",
                    marginLeft: "0.25rem",
                    
                  }}
                >
                  Pool Managers
                </Box>
              </Typography>
              <Divider variant="middle" />

              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} sx={{ mt: 2 }}>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      id="name"
                      name="name"
                      label="Name"
                      type="text"
                      color="error"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      error={name != "" && !validateName(name)}
                      helperText={
                        name != "" && !validateName(name)
                          ? "Please enter a valid name"
                          : ""
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      id="contact"
                      name="contact"
                      label="Contact"
                      type="text"
                      color="error"
                      value={contact}
                      onChange={(e) => setContact(e.target.value)}
                      error={contact != "" && !validateContact(contact)}
                      helperText={
                        contact != "" && !validateContact(contact)
                          ? "Please enter a valid contact no"
                          : ""
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      id="address"
                      name="address"
                      label="Address"
                      type="text"
                      color="error"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      error={address != "" && !validateAddress(address)}
                      helperText={
                        address != "" && !validateAddress(address)
                          ? "Please enter a valid  address: Street, City, State"
                          : ""
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      id="pin"
                      name="pin"
                      label="PIN"
                      type="text"
                      color="error"
                      value={pin}
                      onChange={(e)=>setPin(e.target.value)}
                    />
                  </Grid>
                  
                  
                  
                  <Grid container justifyContent="center">
                      <Grid
                        item
                        xs={4}
                        md={5}
                        container
                        justifyContent="center"
                        sx={{ m: 2 }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="error"
                          fullWidth
                          className={classes.submitButton}
                        >
                          Submit
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        md={5}
                        container
                        justifyContent="center"
                        sx={{ m: 2 }}
                      >
                        <Button
                          variant="contained"
                          color="error"
                          fullWidth
                          onClick={() =>
                            (window.location.href =
                              "/manager/building/checkpool")
                          }
                        >
                        pool Mangers
                        </Button>
                      </Grid>
                    </Grid>
                  
                  
                  
                </Grid>
              </form>

              
            </Paper>
          </Container>
        </Box>
      </Box>
      <Footer />
    </div>
  );
};

export default Chat;
