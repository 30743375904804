import React, { useState, useEffect } from "react";
import ResponsiveAppBar from "../../Components/header.tsx";
import Box from "@mui/material/Box";
import img1 from "../../Assets/resident.jpg";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Footer from "../../Components/Footer.tsx";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import axios from "axios";
import { RESIDENT_URL } from "../../APIendpoints.js";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  gradientPaper: {
    //   background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    backgroundImage:
      "linear-gradient(to right top, #485555, #596468, #6e737a, #838289, #989297, #a6949d, #b6959e, #c5969a, #d1878b, #db777a, #e36668, #e95353)",
    padding: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
        zoom: '85%', // adjust zoom for xs only
      },
  },
  gridContainerButtons: {
    
    [theme.breakpoints.only('xs')]: {
        zoom: '80%', // adjust zoom for xs only
      },
  },
  table: {
    minWidth: 650,
    '& tbody tr:hover': {
      backgroundColor: '#ffa7a7',
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  
  function createData(
    what: string,
    where: string,
    when: string,
    id: string,
    price: string,
    attending: string,
    type: string,
    userid: string
  ) {
    return { what, where, when, id, price, attending, type, userid};
  }
  
  
  

const ResidentActivities = () => {

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  
  useEffect(() => {
    let role = ["temp"];
    role[0] = localStorage.getItem('userRole');
    if(role[0] === "visitor"){
      window.location.href = '/visitor';
    }else if(role[0] === "resident"){
      console.log("Hii")
    }else if(role[0] === "buildingmanager"){
      window.location.href = '/manager/building';
    }else if(role[0] === "poolmanager"){
      window.location.href = '/manager/pool';
    }else if(role[0] === "gardenmanager"){
      window.location.href = '/manager/garden';
    }else if(role[0] === "securitymanager"){
      window.location.href = '/manager/security';
    }else{
      localStorage.clear();
    }

    axios
      .post(RESIDENT_URL, {
        request_type: "get activity data",
        userid: localStorage.getItem("userId"),
        userrole: localStorage.getItem("userRole"),
      })
      .then(function (response) {
        console.log(response)
        if (response.data.status == "success"){
          const responsedata = response.data.activity_details
          const newRows = responsedata.map((data) =>
            createData(data.eventname ,data.eventwhere, data.eventwhen, data.eventid, data.eventprice, data.attending, data.type, data.userid)
          );
          setRows(newRows);
        }else{
          alert("Server Error, Please log back again and check")
        }
      })
      .catch(function (error) {
        console.log(error);
      });    

  }, []);

  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [open, setOpen] = useState(false);

  const handleRowClick = (row) => {
    console.log(row)
    axios
        .post(RESIDENT_URL, {
          request_type: "join leave activity",
          userid: localStorage.getItem("userId"),
          userrole: localStorage.getItem("userRole"),
          eventid: row.id,
          attending: row.attending,
        })
        .then(function (response) {
          console.log(response)
          console.log("On top", rows)
          if (response.data.status == "success"){
            console.log("yes")
            if( row.attending == "false"){
              row.attending = "true"
              row.userid = localStorage.getItem('userId')

              rows.filter(r => r.id != row.id)
              console.log("update false to true", row)
              console.log("rows", rows)
              console.log("isArray:", Array.isArray(rows))
              //const newRow = createData(row.what, row.where, row.when, row.id, row.price, "true", row.type, localStorage.getItem('userId'))
              //console.log("isArray:", Array.isArray(newRow))
              //console.log('new row', newRow)
              //setRows([...rows, newRow])
            }else if(row.attending == "true"){
              row.attending = "false"
              row.userid = null
              rows.filter(r => r.id != row.id)
              console.log("update true to false", row)
              console.log("rows", rows)
              console.log("isArray:", Array.isArray(rows))
              //const newRow = createData(row.what, row.where, row.when, row.id, row.price, "false", row.type, null)
              //console.log("isArray:", Array.isArray(newRow))
              //console.log('new row', newRow)
              //setRows([...rows, newRow])
            }
            setErrorMessage(response.data.message);
            setOpen(true)
          }else{
            alert("Server Error, Please log back again and check")
          }
        })
        .catch(function (error) {
          console.log(error);
        });   
  }

  return (
    <div className="home bgimg">
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
        }}
      >
        <ResponsiveAppBar />
      </Box>
      <Box
        class="candles"
        style={{
          backgroundImage: `url(${img1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          color: "#f5f5f5",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          component="span"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            //   backgroundColor: 'rgba(0,0,0,0.4)',
          }}
        />

        <Box
          sx={{
            position: "relative",
            zIndex: 2,
            width: "100%",
            height: "100%",
          }}
        >
          <Container
            component="main"
            maxWidth="md"
            sx={{ mb: 4, }}
          >
            <Grid container sx={{ m: 2 }} className={classes.gridContainerButtons}>
              <Grid item xs={5} md={3} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() => (window.location.href = "/resident/regveh")}
                >
                  Register Vehicles
                </Button>
              </Grid>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/resident/activities")
                  }
                >
                  Activities
                </Button>
              </Grid>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/resident/memberships")
                  }
                >
                  Memberships
                </Button>
              </Grid>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() => (window.location.href = "/resident/chat")}
                >
                  Chat
                </Button>
              </Grid>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() => (window.location.href = "/resident/payment")}
                >
                  Payment
                </Button>
              </Grid>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() => (window.location.href = "/resident/profile")}
                >
                  Profile
                </Button>
              </Grid>
            </Grid>

            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
              className={classes.gradientPaper}
            >
              <Typography
                component="h3"
                variant="h5"
                align="center"
                sx={{
                  mb: 2,
                  fontSize: "1.5rem",
                  display: "block",
                  fontFamily: '"Montserrat", sans-serif',
                }}
              >
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
                <Box sx={{ display: "inline" }}> Live in with glory at</Box>
                <Box
                  component="span"
                  sx={{
                    color: "red",
                    marginLeft: "0.25rem",
                    textTransform: "uppercase",
                  }}
                >
                  Terrazas de Guacuco
                </Box>
              </Typography>
              <Divider variant="middle" />
              
              <TableContainer component={Paper} sx={{mt:2}}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table" className={classes.table}>
        <TableHead>
          <TableRow>
            <StyledTableCell align="right">ID</StyledTableCell>
            <StyledTableCell>Whats Happening</StyledTableCell>
            <StyledTableCell align="right">Where?</StyledTableCell>
            <StyledTableCell align="right">When</StyledTableCell>
            <StyledTableCell align="right">Price</StyledTableCell>
            <StyledTableCell align="right">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell component="th" scope="row">
                {row.id}
              </StyledTableCell>
              <StyledTableCell align="right">{row.what}</StyledTableCell>
              <StyledTableCell align="right">{row.where}</StyledTableCell>
              <StyledTableCell align="right">{row.when}</StyledTableCell>
              <StyledTableCell align="right">{row.price}</StyledTableCell>
              <StyledTableCell align="right" onClick={() => handleRowClick(row)}> { row.attending != "true" ? "Join" : "Leave"}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

            </Paper>
          </Container>
        </Box>
      </Box>
      <Footer />
    </div>
  );
};

export default ResidentActivities;
