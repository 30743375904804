import React from 'react'
import ResponsiveAppBar from '../Components/header2.tsx'
import Box from '@mui/material/Box';
import img1 from '../Assets/img1.jpg';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Footer from '../Components/Footer.tsx';

const Homepage = () => {
  return (
    <div className="home bgimg">
         <Box
        sx={{
          position: 'relative',
          zIndex: 2,
        }}
      >
        <ResponsiveAppBar/>
        </Box>
        <Box
            class="candles"
            style={{
            backgroundImage: `url(${img1})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100vh",
            color: "#f5f5f5",
            display: 'flex',
            alignItems: 'center',
        }}>
             <Box
        component="span"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0,0,0,0.4)',
        }}
      />
       <Box
        sx={{
          position: 'relative',
          zIndex: 2,
          width: '100%',
            height: '100%',
        }}
      >
           <Grid container
                spacing={0}
                align="center"
                justify="center"
                direction="column"
                style={{
                    height: '100%', 
                    maxWidth: '80%', margin: '0 auto'
                }}
                display="flex"
                justifyContent="center"
                alignItems="center"
           >
                 <Grid item 
                  style={{  wordWrap: 'break-word',display: { xs: 'none', md: 'flex' },}}
                align="center"
                justify="center"
                 >
                 <Typography
                    variant="h2"
                    noWrap
                    component="p"
                    sx={{
                        justifyContent:"center",
                        fontFamily: '"Montserrat", sans-serif',
                        display: { xs: 'none', md: 'flex' },
                        wordWrap:'break-word',
                        overflowWrap: 'break-word',
                        fontWeight:'bold',
                        textTransform:'uppercase'
                    }}
                >
                Welcome to
                </Typography>
                <Typography
                    variant="h2"
                    noWrap
                    component="p"
                    sx={{
                        mr: 2,
                        justifyContent:"center",
                        fontFamily: '"Montserrat", sans-serif',
                        display: { xs: 'none', md: 'flex' },
                        letterSpacing: '.3rem',
                        marginBottom: '20px',
                        wordWrap:'break-word',
                        overflowWrap: 'break-word',
                        color:'red',
                        fontWeight:'bold',
                        textTransform:'uppercase'
                    }}
                >
                Guaco Complex
                </Typography>

                <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    href=""
                    sx={{
                    mr: 2,
                    display: { xs: 'flex', md: 'none' },
                    justifyContent:"center",
                    flexGrow: 1,
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: 100,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                    textTransform: 'uppercase',
                    }}
                >
                    <Box sx={{ display: 'inline' }}>Welcome to  </Box>                
                </Typography>

                <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    href=""
                    sx={{
                    mr: 2,
                    display: { xs: 'flex', md: 'none' },
                    justifyContent:"center",
                    flexGrow: 1,
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: 100,
                    letterSpacing: '.3rem',
                    color: 'red',
                    textDecoration: 'none',
                    textTransform: 'uppercase',
                    
                    }}
                >
                    Guacuco Complex
                
                </Typography>

                    <h3>
                    Terrazas de Guacuco is a luxurious housing complex located in the heart of a beautiful coastal city. With 367 residential units, it offers a wide range of living options, including Studios, Town Houses, and Cabins. Each unit is designed with comfort, style and functionality in mind, making it a perfect place for everyone to call home.
                    </h3>
                    <Button variant="outlined"style={{color: 'red', backgroundColor: 'white', borderColor: 'red'}} onClick={() => window.location.href='/contact'} >Contact Us</Button>
                </Grid> 
           </Grid>
           </Box>
        </Box>
        <Footer/>
    </div>
  )
}

export default Homepage

