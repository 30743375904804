import React, {useState, useEffect} from "react";
import ResponsiveAppBar from "../../../Components/header.tsx";
import Box from "@mui/material/Box";
import img1 from "../../../Assets/garden.jpg";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Footer from "../../../Components/Footer.tsx";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Link from '@mui/material/Link';
import axios from "axios";
import { GARDEN_URL } from "../../../APIendpoints.js";

const useStyles = makeStyles((theme) => ({
  gradientPaper: {
    //   background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    backgroundImage:
      "linear-gradient(to right top, #485555, #596468, #6e737a, #838289, #989297, #a6949d, #b6959e, #c5969a, #d1878b, #db777a, #e36668, #e95353)",
    padding: theme.spacing(2),
    zoom:"90%",
    [theme.breakpoints.only('xs')]: {
      zoom: '80%', // adjust zoom for xs only
    },
  },
  gridContainerButtons: {
    [theme.breakpoints.only('xs')]: {
        zoom: '85%', // adjust zoom for xs only
      },
  },
  table: {
    minWidth: 650,
    "& tbody tr:hover": {
      backgroundColor: "#ffa7a7",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(
    what: string,
    where: string,
    when: string,
    id: string,
    price: string,
    attending: string,
    type: string,
    userid: string
  ) {
    return { what, where, when, id, price, attending, type, userid};
  }


const Chat = () => {
  const classes = useStyles();
  const [account, setAccount] = React.useState('');
  const [rows, setRows] = useState([])
  const [name, setName] = useState("");
  const [makemodel, setMakemodel] = useState("");
  const [vin, setVin] = useState("");
  const [aptno, setAptno] = useState("")

  const handleChange = (event: SelectChangeEvent) => {
    setAccount(event.target.value as string);
  };

  useEffect(() => {
    let role = ["temp"];
    role[0] = localStorage.getItem('userRole');
    if(role[0] === "visitor"){
      window.location.href = '/visitor';
    }else if(role[0] === "resident"){
      window.location.href = '/resident';
    }else if(role[0] === "buildingmanager"){
      window.location.href = '/manager/building';
    }else if(role[0] === "poolmanager"){
      
      window.location.href = '/manager/pool';
    }else if(role[0] === "gardenmanager"){
      console.log("Hi")
    }else if(role[0] === "securitymanager"){
      window.location.href = '/manager/security';
    }else{
      localStorage.clear();
    }

    axios
      .post(GARDEN_URL, {
        request_type: "get hosted event data",
        userid: localStorage.getItem("userId"),
        userrole: localStorage.getItem("userRole"),
      })
      .then(function (response) {
        console.log(response)
        response.data.status === "success"
          ? handleSuccess(response)
          : handleFailure(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });    

  }, []);

  const handleFailure = (response) => {
    // setErrorMessage(response.data.error);
    // setOpen(true)
    // setPassword("");
    console.log("Failed")
  };

  const handleSuccess = (response) => {
    const responsedata = response.data.activity_details
    const newRows = responsedata.map((data) =>
    createData(data.eventname ,data.eventwhere, data.eventwhen, data.eventid, data.eventprice, data.attending, data.type, data.userid)
    );
    setRows(newRows);
  };

  //where
  const validateMakemodel = (makemodel) => {
    const re = /^((GARDEN)|(Garden)|(garden))[1-4]{1}$/;
    return re.test(makemodel);
  };

  //price
  const validateVin = (vin) => {
    const re = /^[0-9]{1,6}$/;
    return re.test(vin);
  };

  //date
  const validateAptno = (name) => {
    const re = /^[0-9]{2}(-)[0-9]{2}(-)[0-9]{4}$/;
    return re.test(name);
  };

  //
  const validateName = (name) => {
    return name.length >= 3
  };


  const changePoolAccess = (row) => {
    console.log(row)
    row.poolaccess = row.poolaccess == "YES" ? "NO" : "YES"
    axios
        .post(GARDEN_URL, {
          request_type: "delete event",
          id:row.id,
        })
        .then(function (response) {
          console.log(response)
          window.location.reload()
          
          if (response.data.status == "success"){
            const newRows = rows.filter(r => r.id != row.id)

            setRows([...newRows, row])
            
          }else{
            alert("Error occured please try again")
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    const abcd = (event) => {
        event.preventDefault();
        
        axios
          .post(GARDEN_URL, {
            request_type: "register event pool",
            makemodel: makemodel,
            vin: vin,
            aptno: aptno,
            name: name,
            userid: localStorage.getItem("userId"),
            userrole: localStorage.getItem("userRole"),
          })
          .then(function (response) {
            console.log("hi"+response)
              setRows([...rows, createData(name, makemodel , aptno, vin )])
              setName("")
              setAptno("")
              setMakemodel("")
              setVin("")
              window.location.reload()
          })
          .catch(function (error) {
            console.log(error);
          });
      };

  return (
    <div className="home bgimg">
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
        }}
      >
        <ResponsiveAppBar />
      </Box>
      <Box
        class="candles"
        style={{
          backgroundImage: `url(${img1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          color: "#f5f5f5",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          component="span"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            //   backgroundColor: 'rgba(0,0,0,0.4)',
          }}
        />

        <Box
          sx={{
            position: "relative",
            zIndex: 2,
            width: "100%",
            height: "100%",
          }}
        >
          <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
          <Grid container sx={{ m: 2 }} className={classes.gridContainerButtons}>
          <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() => (window.location.href = "/manager/garden/timings")}
                  
                >
                  Manage Timings
                </Button>
              </Grid>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/manager/garden/resident")
                  }
                >
                  Manage Resident
                </Button>
              </Grid>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/manager/garden/visitor")
                  }
                >
                  Manage Visitor
                </Button>
              </Grid>
              <Grid item xs={5} md={1.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/manager/garden/chat")
                  }
                >
                  Chat
                </Button>
              </Grid>

              <Grid item xs={5} md={1} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/manager/garden/report")
                  }
                >
                  Report
                </Button>
              </Grid>
              

              <Grid item xs={5} md={1} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/manager/garden/Ads")
                  }
                >
                  Ads
                </Button>
              </Grid>
            </Grid>

            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
              className={classes.gradientPaper}
            >
              <Typography
                component="h3"
                variant="h5"
                align="center"
                sx={{
                  mb: 2,
                  fontSize: "1.5rem",
                  display: "block",
                  fontFamily: '"Montserrat", sans-serif',
                }}
              >
                <Box sx={{ display: "inline" }}>
                  {" "}
                  Register
                </Box>
                <Box
                  component="span"
                  sx={{
                    color: "red",
                    marginLeft: "0.25rem",
                    
                  }}
                >
                  Event/Ads
                </Box>
              </Typography>

              <form onSubmit={abcd}>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      id="name"
                      name="name"
                      label="Name"
                      type="text"
                      color="error"
                      value={name}
                      onChange={(e)=>setName(e.target.value)}
                      error={name != "" && !validateName(name)}
                      helperText={
                        name != "" && !validateName(name)
                          ? "Please enter a valid event name"
                          : ""
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      id="aptno"
                      name="aptno"
                      label="When is event happening?"
                      type="text"
                      color="error"
                      value={aptno}
                      onChange={(e)=>setAptno(e.target.value)}
                      error={aptno != "" && !validateAptno(aptno)}
                      helperText={
                        aptno != "" && !validateAptno(aptno)
                          ? "Please enter a valid date in format MM/DD/YYYY"
                          : ""
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      id="makemodel"
                      name="makemodel"
                      label={"Where is event happening"}
                      type="text"
                      color="error"
                      value={makemodel}
                      onChange={(e) => setMakemodel(e.target.value)}
                      error={makemodel != "" && !validateMakemodel(makemodel)}
                      helperText={
                        makemodel != "" && !validateMakemodel(makemodel)
                          ? "Please enter a valid place for event"
                          : ""
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      id="vin"
                      name="vin"
                      label="Price"
                      type="text"
                      color="error"
                      value={vin}
                      onChange={(e) => setVin(e.target.value)}
                      error={vin != "" && !validateVin(vin)}
                      helperText={
                        vin != "" && !validateVin(vin)
                          ? "Please enter a valid Price"
                          : ""
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    justifyContent="center"
                    sx={{ mb: 2 }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="error"
                      className={classes.submitButton}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>

              

              <Divider variant="middle" />

              <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table
                  sx={{ minWidth: 700 }}
                  aria-label="customized table"
                  className={classes.table}
                > 
                  <TableHead>
                    <TableRow>
                      <StyledTableCell >Name</StyledTableCell>
                      <StyledTableCell align="center">Where</StyledTableCell>
                      <StyledTableCell align="center">When</StyledTableCell>
                      <StyledTableCell align="left">Price</StyledTableCell>
                      
                      <StyledTableCell align="center">Action</StyledTableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={row.id}>
                        <StyledTableCell component="th" scope="row">
                          {row.what}
                        </StyledTableCell>
                        
                        <StyledTableCell align="center">
                          {row.where}
                        </StyledTableCell>
                      
                        <StyledTableCell align="center">
                          {row.when}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          {row.price}
                        </StyledTableCell>  

                        <StyledTableCell align="center">
                          <Link onClick={() => changePoolAccess(row)}>Delete</Link>
                        </StyledTableCell>  

                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              

              
              
            </Paper>
          </Container>
        </Box>
      </Box>
      <Footer />
    </div>
  );
};

export default Chat;
