import React, {useState, useEffect} from "react";
import ResponsiveAppBar from "../../../Components/header.tsx";
import Box from "@mui/material/Box";
import img1 from "../../../Assets/pool.jpg";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Footer from "../../../Components/Footer.tsx";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Link from "@mui/material/Link";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import { POOL_URL } from "../../../APIendpoints.js";

const useStyles = makeStyles((theme) => ({
  gradientPaper: {
    //   background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    backgroundImage:
      "linear-gradient(to right top, #485555, #596468, #6e737a, #838289, #989297, #a6949d, #b6959e, #c5969a, #d1878b, #db777a, #e36668, #e95353)",
    padding: theme.spacing(2),
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
    zoom: "90%",
    [theme.breakpoints.only("xs")]: {
      zoom: "85%", // adjust zoom for xs only
    },
  },
  gridContainerButtons: {
    [theme.breakpoints.only("xs")]: {
      zoom: "85%", // adjust zoom for xs only
    },
  },
  table: {
    minWidth: 650,
    "& tbody tr:hover": {
      backgroundColor: "#ffa7a7",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(from: string, last: string, action: string) {
  return { from, last, action };
}



const Chat = () => {
  const classes = useStyles();
  const [account, setAccount] = React.useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [timings, setTimings] = useState("");

  useEffect(() => {
    let role = ["temp"];
    role[0] = localStorage.getItem('userRole');
    if(role[0] === "visitor"){
      window.location.href = '/visitor';
    }else if(role[0] === "resident"){
      window.location.href = '/resident';
    }else if(role[0] === "buildingmanager"){
      window.location.href = '/manager/building';
    }else if(role[0] === "poolmanager"){
      console.log("Hi")
    }else if(role[0] === "gardenmanager"){
      window.location.href = '/manager/garden';
    }else if(role[0] === "securitymanager"){
      window.location.href = '/manager/security';
    }else{
      localStorage.clear();
    }
  
    
  }, [])

  const handleChange = (event: SelectChangeEvent) => {
    setAccount(event.target.value as string);
  };


  const validateTimings = (timings) => {
    const re = /^(([1-9]|[1][0-2])(AM|PM)-([1-9]|[1][0-2])(AM|PM)(, )?(,)?)+$/;
    return re.test(timings);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    console.log(new Date(from).toLocaleDateString())
    console.log(new Date(to) > new Date(from))
    console.log(to)

    if(new Date(to) > new Date(from)){  
      axios
      .post(POOL_URL, {
        request_type: "update pool timings",
        userid: localStorage.getItem("userId"),
        userrole: localStorage.getItem("userRole"),
        pool: account,
        from: new Date(from).toLocaleDateString(),
        to: new Date(to).toLocaleDateString(),
        timings: timings,
      })
      .then(function (response) {
        console.log(response)
        if(response.data.status == 'success'){
          
          alert("Timings updated for "+account+" Successfully")
          setAccount("")
          setFrom("")
          setTo("")
          setTimings("")
        }else{
          alert("Please try again")
        }
       
      })
      .catch(function (error) {
        console.log(error);
      });
    }else{
      alert("Please select proper dates")
    }
};

  return (
    <div className="home bgimg">
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
        }}
      >
        <ResponsiveAppBar />
      </Box>
      <Box
        class="candles"
        style={{
          backgroundImage: `url(${img1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          color: "#f5f5f5",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          component="span"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            //   backgroundColor: 'rgba(0,0,0,0.4)',
          }}
        />

        <Box
          sx={{
            position: "relative",
            zIndex: 2,
            width: "100%",
            height: "100%",
          }}
        >
          <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
          <Grid container sx={{ m: 2 }} className={classes.gridContainerButtons}>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() => (window.location.href = "/manager/pool/timings")}
                  
                >
                  Manage Timings
                </Button>
              </Grid>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/manager/pool/resident")
                  }
                >
                  Manage Resident
                </Button>
              </Grid>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/manager/pool/visitor")
                  }
                >
                  Manage Visitor
                </Button>
              </Grid>
              <Grid item xs={5} md={1} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/manager/pool/chat")
                  }
                >
                  Chat
                </Button>
              </Grid>

              <Grid item xs={5} md={1.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/manager/pool/report")
                  }
                >
                  Report
                </Button>
              </Grid>

              <Grid item xs={5} md={1.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/manager/pool/Ads")
                  }
                >
                  Ads
                </Button>
              </Grid>
              
            </Grid>

            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
              className={classes.gradientPaper}
            >
              <Typography
                component="h3"
                variant="h5"
                align="center"
                sx={{
                  mb: 2,
                  fontSize: "1.5rem",
                  display: "block",
                  fontFamily: '"Montserrat", sans-serif',
                }}
              >
                <Box sx={{ display: "inline" }}> Manage</Box>
                <Box
                  component="span"
                  sx={{
                    color: "red",
                    marginLeft: "0.25rem",
                  }}
                >
                  Timings
                </Box>
              </Typography>
              <Divider variant="middle" />

              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel color="error" id="demo-simple-select-label">
                        Select Pool
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={account}
                        label="Account"
                        color="error"
                        onChange={handleChange}
                      >
                        <MenuItem value={"Pool 1"}>Pool 1</MenuItem>
                        <MenuItem value={"Pool 2"}>Pool 2</MenuItem>
                        <MenuItem value={"Pool 3"}>Pool 3</MenuItem>
                        <MenuItem value={"Pool 4"}>Pool 4</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel>From</InputLabel>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        name="from"
                        id="from"
                        fullWidth
                        className={classes.datePicker}
                        value={from}
                        onChange={(e) => setFrom(e)}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel>To</InputLabel>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        name="to"
                        inputFormat="DD-MM-YYYY"
                        id="to"
                        fullWidth
                        className={classes.datePicker}
                        value={to}
                        onChange={(e) => setTo(e)}
                      />
                    </LocalizationProvider>
                  </Grid>


                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      id="timings"
                      name="timings"
                      label="Timings (Ex: 7AM-12PM)"
                      type="text"
                      color="error"
                      value={timings}
                      onChange={(e) => setTimings(e.target.value)}
                      error={timings != "" && !validateTimings(timings)}
                      helperText={
                        timings != "" && !validateTimings(timings)
                          ? "Please enter a valid VIN (ex: 7AM-12PM)"
                          : ""
                      }
                    />
                  </Grid>

                  {/* <Grid item xs={12} container justifyContent="center" sx={{mb:2}}>
                    <Grid container justifyContent="center">
                        <Grid item xs={6} justifyContent="center">
                        <Button
                      type="submit"
                      variant="contained"
                      color="error"
                      className={classes.submitButton}
                    >
                      Submit
                    </Button>
                        </Grid>
                        <Grid item xs={6} justifyContent="center">
                        <Link href="/manager/pool/checktimings">Check Pool Timings</Link>
                        </Grid>
                    </Grid>
                    
                  </Grid>
                  
                  <Grid item xs={12} container justifyContent="center" sx={{mb:2}}>
                    <Link href="/manager/pool/checktimings">Check Pool Timings</Link>
                  </Grid> */}

                  <Grid
                    item
                    xs={12}
                    container
                    justifyContent="center"
                    sx={{ mb: 2 }}
                  >
                    <Grid container justifyContent="center">
                      <Grid
                        item
                        xs={5}
                        md={5}
                        container
                        justifyContent="center"
                        sx={{ m: 2 }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="error"
                          fullWidth
                          className={classes.submitButton}
                        >
                          Submit
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        md={5}
                        container
                        justifyContent="center"
                        sx={{ m: 2 }}
                      >
                        <Button
                          variant="contained"
                          color="error"
                          fullWidth
                          onClick={() =>
                            (window.location.href =
                              "/manager/pool/checktimings")
                          }
                        >
                          Check Pool Timings
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Container>
        </Box>
      </Box>
      <Footer />
    </div>
  );
};

export default Chat;
