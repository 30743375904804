import React, {useState} from "react";
import ResponsiveAppBar from "../Components/header2.tsx";
import Box from "@mui/material/Box";
import img1 from "../Assets/img1.jpg";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Footer from "../Components/Footer.tsx";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";

import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import emailjs from 'emailjs-com';


const useStyles = makeStyles((theme) => ({
  gradientPaper: {
    //   background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    backgroundImage:
      "linear-gradient(to right top, #485555, #596468, #6e737a, #838289, #989297, #a6949d, #b6959e, #c5969a, #d1878b, #db777a, #e36668, #e95353)",
    padding: theme.spacing(2),
  },
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[2],
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: theme.breakpoints.values.sm,
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
  customOutline: {
    borderColor: `#FF0000`, 
    '& label.Mui-focused': {
      color: `#FF0000`, 
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: `#FF0000`,
    },
  },
}));

const Contact = () => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [contact, setContact] = useState();
  const [subject, setSubject] = useState();

  const [fromname, setFromname] = useState();
  const [toname, setToname] = useState();
  const [message, setMessage] = useState();
  const [replyto, setReplyto] = useState();


  const handleSubmit = (event) => {
    event.preventDefault();

    setMessage("Thank you for your Inquiry: \nName: "+name+"\n Contact: "+contact+"\n Message: "+subject)

    emailjs.sendForm('service_w5hrydr', 'template_5d6hw4h', '#myForm', 'hWylgXyZoZyDRSsZC')
        .then((result) => {
          console.log(result.text);
          setName("")
          setEmail("")
          setContact("")
          setSubject("")
        }, (error) => {
          alert("Internal Server Error:\n"+error.text)
          console.log(error.text);
        });
  };


  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <div className="home bgimg">
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
        }}
      >
        <ResponsiveAppBar />
      </Box>
      <Box
        class="candles"
        style={{
          backgroundImage: `url(${img1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          color: "#f5f5f5",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          component="span"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            //   backgroundColor: 'rgba(0,0,0,0.4)',
          }}
        />
        <Box
          sx={{
            position: "relative",
            zIndex: 2,
            width: "100%",
            height: "100%",
          }}
        >
          <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
              className={classes.gradientPaper}
            >
              <Typography
                component="h3"
                variant="h5"
                align="center"
                sx={{
                  mb: 2,
                  fontSize: "1.5rem",
                  display: "block",
                  fontFamily: '"Montserrat", sans-serif',
                }}
              >
                <Box sx={{ display: "inline" }}>
                  {" "}
                  Please fill the below form and we will
                </Box>
                <Box
                  component="span"
                  sx={{
                    color: "red",
                    marginLeft: "0.25rem",
                    
                  }}
                >
                  contact you!
                </Box>
              </Typography>
              <Divider variant="middle" />

              <form id="myForm" style={{display:"none"}}>
                <div class="field">
                  <label for="from_name">from_name</label>
                  <input type="text" name="from_name" value="Terrazas De Guacuco" id="from_name"/>
                </div>
                <div class="field">
                  <label for="to_name">to_name</label>
                  <input type="text" name="to_name" value={email} id="to_name"/>
                </div>
                <div class="field">
                  <label for="message">message</label>
                  <input type="text" name="message" value={message} id="message"/>
                </div>
                <div class="field">
                  <label for="reply_to">reply_to</label>
                  <input type="text" name="reply_to" value="terrazasdeguacuco24@gmail.com" id="reply_to"/>
                </div>

                <input type="submit" id="button" value="Send Email"/>
              </form>
              
                  <form  onSubmit={handleSubmit}>
                    
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          required
                          id="name"
                          name="name"
                          color="error"
                          label="Name"
                          value={name}
                          onChange={(e)=>setName(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          required
                          id="email"
                          name="email"
                          label="Email"
                          type="email"
                          color="error"
                          value={email}
                          onChange={(e)=>setEmail(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          required
                          id="contact"
                          color="error"
                          name="contact"
                          label="Contact"
                          value={contact}
                          onChange={(e)=>setContact(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          required
                          id="subject"
                          name="subject"
                          label="Subject"
                          color="error"
                          value={subject}
                          onChange={(e)=>setSubject(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} container justifyContent="center">
              <Button
                type="submit"
                variant="contained"
                color="error"
                className={classes.submitButton}
              >
                Submit
              </Button>
            </Grid>
                    </Grid>
                    
                  </form>
            
            </Paper>
          </Container>
        </Box>
      </Box>
      <Footer />
    </div>
  );
};

export default Contact;
