import React, {useState} from "react";
import ResponsiveAppBar from "../Components/header2.tsx";
import Box from "@mui/material/Box";
import img1 from "../Assets/img1.jpg";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Footer from "../Components/Footer.tsx";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";

import Link from "@mui/material/Link";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import { FORGOT_URL } from "../APIendpoints.js";

const useStyles = makeStyles((theme) => ({
  gradientPaper: {
    //   background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    backgroundImage:
      "linear-gradient(to right top, #485555, #596468, #6e737a, #838289, #989297, #a6949d, #b6959e, #c5969a, #d1878b, #db777a, #e36668, #e95353)",
    padding: theme.spacing(2),
  },
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[2],
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: theme.breakpoints.values.sm,
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
  customOutline: {
    borderColor: `#FF0000`,
    "& label.Mui-focused": {
      color: `#FF0000`,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: `#FF0000`,
    },
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);

  const [email, setEmail]=useState("")
  const [contact, setContact]=useState("")
  const [password, setPassword] = useState("")

  const validatePassword = (password) => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    return re.test(password);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateName = (name) => {
    return name.length >= 3
  };

  const validateAddress = (address) => {
    const myArray = address.split(",");
    const validPart = myArray.filter(part => part !== null && part.length > 0);
    const invalidPart = myArray.filter(part => !(part !== null && part.length > 0));
    return validPart.length >= 3 && invalidPart.length == 0

  };

  const validateContact = (contact) => {
    const re = /^[0-9]{10}$/i;
    return re.test(contact);
  };


  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [account, setAccount] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };

  const handleSubmit = (e) =>{
    e.preventDefault();
    console.log("Hello")
    axios
      .post(FORGOT_URL, {
        email: email,
        password: password,
        contact: contact,
      })
      .then((response) => {
        console.log(response)
        if (response.data.status === "success") {

          window.location.href = "/login";
        } else {
          alert(response.data.message);
        }
      })
      .catch((error) => console.error(error));
  }

  return (
    <div className="home bgimg">
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
        }}
      >
        <ResponsiveAppBar />
      </Box>
      <Box
        class="candles"
        style={{
          backgroundImage: `url(${img1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          color: "#f5f5f5",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          component="span"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            //   backgroundColor: 'rgba(0,0,0,0.4)',
          }}
        />
        <Box
          sx={{
            position: "relative",
            zIndex: 2,
            width: "100%",
            height: "100%",
          }}
        >
          <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
              className={classes.gradientPaper}
            >
              <Typography
                component="h3"
                variant="h5"
                align="center"
                sx={{
                  mb: 2,
                  fontSize: "1.5rem",
                  display: "block",
                  fontFamily: '"Montserrat", sans-serif',
                }}
              >
                <Box sx={{ display: "inline" }}> Forgot Password?  </Box>
                <Box
                  component="span"
                  sx={{
                    color: "red",
                    marginLeft: "0.25rem",
                  }}
                >
                  Fill in details to restore
                </Box>
              </Typography>
              <Divider variant="middle" />

              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      id="email"
                      name="email"
                      label="Email"
                      type="email"
                      color="error"
                      value={email}
                      onChange={(e)=>setEmail(e.target.value)}
                     
                      error={email != "" && !validateEmail(email)}
                      helperText={
                        email != "" && !validateEmail(email)
                          ? "Please enter a valid email address"
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          required
                          id="contact"
                          color="error"
                          name="contact"
                          label="Contact"
                          value={contact}
                          onChange={(e)=>setContact(e.target.value)}
                          error={contact != "" && !validateContact(contact)}
                      helperText={
                        contact != "" && !validateContact(contact)
                          ? "Please enter a valid contact no"
                          : ""
                      }
                        />
                      </Grid>
                      
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      id="otp"
                      name="otp"
                      label="Password"
                      type="password"
                      color="error"
                      value={password}
                      onChange={(e)=>setPassword(e.target.value)}
                      error={password!="" && !validatePassword(password)}
                      helperText={password!="" && !validatePassword(password) ? 'Password should contain at least one uppercase letter, one lowercase letter, one number, and be at least 8 characters long' : ''}
                    
                    />
                  </Grid>
                  
                  <Grid item xs={12} container justifyContent="center">
                    <Button
                      type="submit"
                      variant="contained"
                      color="error"
                      className={classes.submitButton}
                      disabled={!validateEmail(email)  || !validateContact(contact)  || !validatePassword(password)} 

                    >
                      Submit
                    </Button>
                  </Grid>
                  <Grid item xs={12} container justifyContent="center">
                    <Link href="/login" color="primary.dark">
                      Already a member? Login
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Container>
        </Box>
      </Box>
      <Footer />
    </div>
  );
};

export default ForgotPassword;
