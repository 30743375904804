import React, { useState, useEffect } from "react";
import ResponsiveAppBar from "../../Components/header.tsx";
import Box from "@mui/material/Box";
import img1 from "../../Assets/resident.jpg";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Footer from "../../Components/Footer.tsx";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Link from '@mui/material/Link';
import axios from "axios";
import { RESIDENT_URL } from "../../APIendpoints.js";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  gradientPaper: {
    //   background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    backgroundImage:
      "linear-gradient(to right top, #485555, #596468, #6e737a, #838289, #989297, #a6949d, #b6959e, #c5969a, #d1878b, #db777a, #e36668, #e95353)",
    padding: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      zoom: '85%', // adjust zoom for xs only
    },
  },
  gridContainerButtons: {
    
    [theme.breakpoints.only('xs')]: {
        zoom: '80%', // adjust zoom for xs only
      },
  },
  table: {
    minWidth: 650,
    "& tbody tr:hover": {
      backgroundColor: "#ffa7a7",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(nam:string, type:string, from:string, till:string, membershipid:string) {
  return { nam, type, from, till, membershipid };
}

function getMembershipValue(input) {
  switch(input) {
    case 1:
      return "Gym";
    case 2:
      return "Garden";
    case 3:
      return "Pool";
    case 4:
      return "Car Wash";
    default:
      return "Unknown Location";
  }
}


const Memberships = () => {
  const classes = useStyles();
  const [account, setAccount] = React.useState('');
  const [name, setName] = React.useState('');
  const [rows, setRows] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  const handleChange = (event: SelectChangeEvent) => {
    setAccount(event.target.value as string);
  };

  useEffect(() => {
    let role = ["temp"];
    role[0] = localStorage.getItem('userRole');
    if(role[0] === "visitor"){
      window.location.href = '/visitor';
    }else if(role[0] === "resident"){
      console.log("Hii")
    }else if(role[0] === "buildingmanager"){
      window.location.href = '/manager/building';
    }else if(role[0] === "poolmanager"){
      window.location.href = '/manager/pool';
    }else if(role[0] === "gardenmanager"){
      window.location.href = '/manager/garden';
    }else if(role[0] === "securitymanager"){
      window.location.href = '/manager/security';
    }else{
      localStorage.clear();
    }

    axios
      .post(RESIDENT_URL, {
        request_type: "get membership data",
        userid: localStorage.getItem("userId"),
        userrole: localStorage.getItem("userRole"),
      })
      .then(function (response) {
        console.log(response)
        if (response.data.status == "success"){
          const responsedata = response.data.membership_details
          const newRows = responsedata.map((data) =>
            createData(data.name ,data.type, data.validfrom, data.validtill, data.membershipid)
          );
          setRows(newRows);
        }else{
          alert("Server Error, Please log back again and check")
        }
      })
      .catch(function (error) {
        console.log(error);
      });    

  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post(RESIDENT_URL, {
        request_type: "join membership",
        userid: localStorage.getItem("userId"),
        userrole: localStorage.getItem("userRole"),
        membershipid: account,
        name: name,
      })
      .then(function (response) {
        console.log(response)
        
        if (response.data.status == "success"){
          setRows([...rows, createData(name , getMembershipValue(account), response.data.validfrom, response.data.validtill, account)])
          setName("")
          setAccount("")
          setErrorMessage("Successfully joined membership");
          setOpen(true)
        }else{
          setErrorMessage("Server Error, Please log back again and check");
          setOpen(true)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
};

const handleRowClick = (row) => {
  console.log(row)
  axios
      .post(RESIDENT_URL, {
        request_type: "leave membership",
        userid: localStorage.getItem("userId"),
        userrole: localStorage.getItem("userRole"),
        membershipid: row.membershipid,
      })
      .then(function (response) {
        console.log(response)
        
        if (response.data.status == "success"){
          const newRows = rows.filter(r => r.membershipid != row.membershipid)
          setRows(newRows)
          setErrorMessage("Successfully left membership");
          setOpen(true)
        }else{
          setErrorMessage("Server Error, Please log back again and check");
          setOpen(true)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
}

  return (
    <div className="home bgimg">
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
        }}
      >
        <ResponsiveAppBar />
      </Box>
      <Box
        class="candles"
        style={{
          backgroundImage: `url(${img1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          color: "#f5f5f5",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          component="span"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            //   backgroundColor: 'rgba(0,0,0,0.4)',
          }}
        />

        <Box
          sx={{
            position: "relative",
            zIndex: 2,
            width: "100%",
            height: "100%",
          }}
        >
          <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
            <Grid container sx={{ m: 2 }} className={classes.gridContainerButtons}>
              <Grid item xs={5} md={3} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() => (window.location.href = "/resident/regveh")}
                >
                  Register Vehicles
                </Button>
              </Grid>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/resident/activities")
                  }
                >
                  Activities
                </Button>
              </Grid>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/resident/memberships")
                  }
                >
                  Memberships
                </Button>
              </Grid>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() => (window.location.href = "/resident/chat")}
                >
                  Chat
                </Button>
              </Grid>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() => (window.location.href = "/resident/payment")}
                >
                  Payment
                </Button>
              </Grid>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() => (window.location.href = "/resident/profile")}
                >
                  Profile
                </Button>
              </Grid>
            </Grid>

            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
              className={classes.gradientPaper}
            >
              <Typography
                component="h3"
                variant="h5"
                align="center"
                sx={{
                  mb: 2,
                  fontSize: "1.5rem",
                  display: "block",
                  fontFamily: '"Montserrat", sans-serif',
                }}
              >
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
                <Box sx={{ display: "inline" }}>
                  {" "}
                  Join
                </Box>
                <Box
                  component="span"
                  sx={{
                    color: "red",
                    marginLeft: "0.25rem",
                    
                  }}
                >
                  Membership
                </Box>
              </Typography>
              <Divider variant="middle" />


              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      id="name"
                      name="name"
                      label="Name"
                      type="text"
                      color="error"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>
                  
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel color="error" id="demo-simple-select-label">
                        Membership Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={account}
                        label="Age"
                        color="error"
                        onChange={handleChange}
                      >
                        <MenuItem value={1}>
                          Gym
                        </MenuItem>
                        <MenuItem value={2}>
                          Garden
                        </MenuItem>
                        <MenuItem value={3}>pool</MenuItem>
                        <MenuItem value={4}>
                          Car wash
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={12} container justifyContent="center" sx={{mb:2}}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="error"
                      className={classes.submitButton}
                    >
                      Submit
                    </Button>
                  </Grid>
                  
                  
                  
                </Grid>
              </form>

              <Divider variant="middle" />

              <Typography
                component="h3"
                variant="h2"
                align="center"
                sx={{
                  mt: 2,
                  fontSize: "1.5rem",
                  display: "block",
                  fontFamily: '"Montserrat", sans-serif',
                }}
              >
                <Box sx={{ display: "inline" }}>
                  {" "}
                  Active
                </Box>
                <Box
                  component="span"
                  sx={{
                    color: "red",
                    marginLeft: "0.25rem",
                    
                  }}
                >
                  Memberships
                </Box>
              </Typography>

              <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table
                  sx={{ minWidth: 700 }}
                  aria-label="customized table"
                  className={classes.table}
                > 
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell align="center">Type</StyledTableCell>
                      <StyledTableCell align="center">From</StyledTableCell>
                      <StyledTableCell align="center">Till</StyledTableCell>
                      <StyledTableCell align="center">Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={row.membershipid}>
                        <StyledTableCell component="th" scope="row">
                          {row.nam}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.type}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.from}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.till}
                        </StyledTableCell>
                        <StyledTableCell align="center" onClick={() => handleRowClick(row)}>
                          <Link>Leave</Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Container>
        </Box>
      </Box>
      <Footer />
    </div>
  );
};

export default Memberships;
