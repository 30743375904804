import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link, IconButton, Grid } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: 'black',
    color: 'white',
    padding: theme.spacing(2),
    marginTop: 'auto',
  },
  socialIcon: {
    color: 'white',
  },
}));

function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Grid container direction="column" alignItems="center" spacing={1}>
        <Grid item>
          {/* <Typography variant="body1" align="center">
            This is the footer description text.
          </Typography> */}
          <Typography
            variant="body1"
            align="center"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'Montserrat, sans-serif',
              fontWeight: 100,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              textTransform: 'uppercase',
            }}
          >
            <Box sx={{ display: 'inline' }}>TERRAZAS DE</Box>
            <Box component="span" sx={{ color: 'red', marginLeft: '0.25rem' }}>GUACUCO</Box>
          </Typography>
          <br/>
          <Typography variant="body1" align="center">
            Located ayaw from the city's loud noise and busy streets. Terrazas de Guacuco is a plec situated in the middle of lust green woords and offers three types of different homes. Apply early and get exclusive offers.
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2} justify="center">
            <Grid item>
              <Link href="#" color="inherit">
                <IconButton className={classes.socialIcon}>
                  <FacebookIcon />
                </IconButton>
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" color="inherit">
                <IconButton className={classes.socialIcon}>
                  <TwitterIcon />
                </IconButton>
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" color="inherit">
                <IconButton className={classes.socialIcon}>
                  <InstagramIcon />
                </IconButton>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
}

export default Footer;
