import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./View/Homepage.tsx";
import About from "./View/About.tsx";
import Services from "./View/Services.tsx";
import Contact from "./View/Contact.tsx";
import { Login, Signup, ForgotPassword } from "./View/Export.js";
import {
  ResidentActivities,
  ResidentProfile,
  ResidentPayment,
  ResidentRegisterVehicle,
  ResidentMemberships,
  ResidentChat,
  ResidentChatbody,
} from "./View/Resident/Export.js";
import {
  VisitorChat,
  VisitorChatbody,
  VisitorRegisterVehicle,
  VisitorTimings,
  DrivingInstructions,
  VisitorDetails,
} from "./View/Visitor/Export.js";
import {
  BuildingManageSecurity,
  BuildingManagePool,
  BuildingCheckResident,
  BuildingCheckBuildingM,
  BuildingEditUnitDetails,
  BuildingCheckGardenM,
  BuildingCheckPoolM,
  BuildingCheckVisitor,
  BuildingEditDetails,
  BuildingManageGarden,
  BuildingManageResident,
  BuildingManageVisitor,
  BuildingManageReport,
  BuildingManageChat,
  BuildingManageChatBody,
} from "./View/Managers/Building/Export.js";
import {
  PoolManageTimings,
  PoolAds,
  PoolManageResidents,
  PoolManageReport,
  PoolManageVisitors,
  PoolManageChat,
  PoolManageChatBody,
  PoolCheckTimings,
  PoolCheckResidents,
  PoolCheckVisitors,
  PoolEditDetails,
} from "./View/Managers/Pool/Export";
import {
  GardenManageTimings,
  GardenManageResidents,
  GardenManageReport,
  GardenManageVisitors,
  GardenManageChat,
  GardenManageChatBody,
  GardenCheckTimings,
  GardenCheckResidents,
  GardenCheckVisitors,
  GardenEditDetails,
  GardenManagerEvent,
} from "./View/Managers/Garden/Export";
import {
  SecurityManageChat,
  SecurityManageChatBody,
  SecurityManageCheckSecurity,
  SecurityManageSecurity,
  SecurityManageEditSecurityDetails,
  SecurityManageTimings,
  SecurityCheckTimings,
  SecurityCheckResidents,
  SecurityCheckVisitors,
  SecurityManageResidents,
  SecurityManageVisitors,
  SecurityEditDetails,
  SecurityManageReport,
} from "./View/Managers/Security/Export.js";
import socketIO from "socket.io-client";
//const socket = socketIO.connect("http://localhost:8080");
const socket = socketIO.connect("https://terrazaschat.azurewebsites.net");




function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Homepage />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />

        <Route path="/resident" element={<ResidentActivities />} />
        <Route path="/resident/activities" element={<ResidentActivities />} />
        <Route path="/resident/regveh" element={<ResidentRegisterVehicle />} />
        <Route path="/resident/memberships" element={<ResidentMemberships />} />
        <Route path="/resident/chat" element={<ResidentChat socket={socket} />} />
        <Route path="/resident/chatbody" element={<ResidentChatbody socket={socket} />} />
        <Route path="/resident/payment" element={<ResidentPayment />} />
        <Route path="/resident/profile" element={<ResidentProfile />} />

        <Route path="/visitor/" element={<VisitorTimings />} />
        <Route path="/visitor/chat" element={<VisitorChat socket={socket} />} />
        <Route path="visitor/chatbody" element={<VisitorChatbody socket={socket} />} />
        <Route path="/visitor/timings" element={<VisitorTimings />} />
        <Route
          path="/visitor/drivinginstructions"
          element={<DrivingInstructions />}
        />
        <Route path="/visitor/regveh" element={<VisitorRegisterVehicle />} />
        <Route path="/visitor/visitor/details" element={<VisitorDetails />} />

        <Route path="/manager/building" element={<BuildingManageSecurity />} />
        <Route
          path="/manager/building/managesecurity"
          element={<BuildingManageSecurity />}
        />
        <Route path="/manager/building/pool" element={<BuildingManagePool />} />
        <Route
          path="/manager/building/garden"
          element={<BuildingManageGarden />}
        />
        {/* <Route path="/manager/building/resident" element={<BuildingManageResident/>}/>
        <Route path="/manager/building/visitor" element={<BuildingManageVisitor/>}/> */}
        <Route
          path="/manager/building/resident"
          element={<BuildingCheckResident />}
        />
        <Route
          path="/manager/building/visitor"
          element={<BuildingCheckVisitor />}
        />
        <Route
          path="/manager/building/report"
          element={<BuildingManageReport />}
        />
        <Route
          path="/manager/building/checkresident"
          element={<BuildingCheckResident />}
        />
        <Route
          path="/manager/building/checkvisitor"
          element={<BuildingCheckVisitor />}
        />
        <Route
          path="/manager/building/editresidentdetails"
          element={<BuildingEditDetails />}
        />
        <Route
          path="/manager/building/editvisitordetails"
          element={<BuildingEditDetails />}
        />
        <Route
          path="/manager/building/checkbuilding"
          element={<BuildingCheckBuildingM />}
        />
        <Route
          path="/manager/building/checkgarden"
          element={<BuildingCheckGardenM />}
        />
        <Route
          path="/manager/building/checkpool"
          element={<BuildingCheckPoolM />}
        />
        <Route
          path="/manager/building/editgardenmdetails"
          element={<BuildingEditUnitDetails />}
        />
        <Route
          path="/manager/building/editpoolmdetails"
          element={<BuildingEditUnitDetails />}
        />
        <Route
          path="/manager/building/editbuildingmdetails"
          element={<BuildingEditUnitDetails />}
        />
        <Route path="/manager/building/chat" element={<BuildingManageChat socket={socket}/>} />
        <Route path="/manager/building/chatbody" element={<BuildingManageChatBody socket={socket} />} />

        <Route path="/manager/pool" element={<PoolManageTimings />} />
        <Route path="/manager/pool/timings" element={<PoolManageTimings />} />
        {/* <Route path="/manager/pool/resident" element={<PoolManageResidents/>}/>
        <Route path="/manager/pool/visitor" element={<PoolManageVisitors/>}/> */}
        <Route path="/manager/pool/resident" element={<PoolCheckResidents />} />
        <Route path="/manager/pool/visitor" element={<PoolCheckVisitors />} />
        <Route path="/manager/pool/chat" element={<PoolManageChat socket={socket}/>} />
        <Route path="/manager/pool/chatbody" element={<PoolManageChatBody socket={socket} />} />
        <Route
          path="/manager/pool/checktimings"
          element={<PoolCheckTimings />}
        />
        <Route
          path="/manager/pool/checkresident"
          element={<PoolCheckResidents />}
        />
        <Route
          path="/manager/pool/checkvisitor"
          element={<PoolCheckVisitors />}
        />
        <Route
          path="/manager/pool/editresidentdetails"
          element={<PoolEditDetails />}
        />
        <Route
          path="/manager/pool/editvisitordetails"
          element={<PoolEditDetails />}
        />
        <Route path="/manager/pool/report" element={<PoolManageReport />} />
        <Route path="/manager/pool/Ads" element={<PoolAds />} />

        <Route path="/manager/garden" element={<GardenManageTimings />} />
        <Route
          path="/manager/garden/timings"
          element={<GardenManageTimings />}
        />
        {/* <Route path="/manager/garden/resident" element={<GardenManageResidents/>}/>
        <Route path="/manager/garden/visitor" element={<GardenManageVisitors/>}/> */}
        <Route
          path="/manager/garden/resident"
          element={<GardenCheckResidents />}
        />
        <Route
          path="/manager/garden/visitor"
          element={<GardenCheckVisitors />}
        />
        <Route path="/manager/garden/chat" element={<GardenManageChat socket={socket}/>} />
        <Route
          path="/manager/garden/chatbody"
          element={<GardenManageChatBody socket={socket}/>}
        />
        <Route
          path="/manager/garden/checktimings"
          element={<GardenCheckTimings />}
        />
        <Route
          path="/manager/garden/checkresident"
          element={<GardenCheckResidents />}
        />
        <Route
          path="/manager/garden/checkvisitor"
          element={<GardenCheckVisitors />}
        />
        <Route
          path="/manager/garden/editresidentdetails"
          element={<GardenEditDetails />}
        />
        <Route
          path="/manager/garden/editvisitordetails"
          element={<GardenEditDetails />}
        />
        <Route path="/manager/garden/report" element={<GardenManageReport />} />
        <Route path="/manager/garden/Ads" element={<GardenManagerEvent />} />


        <Route path="/manager/security/" element={<SecurityManageSecurity />} />
        <Route path="/manager/security/chat" element={<SecurityManageChat socket={socket}/>} />
        <Route
          path="/manager/security/chatbody"
          element={<SecurityManageChatBody socket={socket}/>}
        />
        <Route
          path="/manager/security/security"
          element={<SecurityManageSecurity />}
        />
        <Route
          path="/manager/security/checksecurity"
          element={<SecurityManageCheckSecurity />}
        />
        <Route
          path="/manager/security/editsecuritydetails"
          element={<SecurityManageEditSecurityDetails />}
        />
        <Route
          path="/manager/security/checktimings"
          element={<SecurityCheckTimings />}
        />
        <Route
          path="/manager/security/timings"
          element={<SecurityManageTimings />}
        />
        {/* <Route path="/manager/security/resident" element={<SecurityManageResidents/>}/>
        <Route path="/manager/security/visitor" element={<SecurityManageVisitors/>}/> */}
        <Route
          path="/manager/security/resident"
          element={<SecurityCheckResidents />}
        />
        <Route
          path="/manager/security/visitor"
          element={<SecurityCheckVisitors />}
        />
        <Route
          path="/manager/security/checkresident"
          element={<SecurityCheckResidents />}
        />
        <Route
          path="/manager/security/checkvisitor"
          element={<SecurityCheckVisitors />}
        />
        <Route
          path="/manager/security/editresidentdetails"
          element={<SecurityEditDetails />}
        />
        <Route
          path="/manager/security/editvisitordetails"
          element={<SecurityEditDetails />}
        />
        <Route
          path="/manager/security/report"
          element={<SecurityManageReport />}
        />
      </Routes>
    </Router>
  );
}

export default App;
