import React,{useState, useEffect} from "react";
import ResponsiveAppBar from "../../../Components/header.tsx";
import Box from "@mui/material/Box";
import img1 from "../../../Assets/garden.jpg";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Footer from "../../../Components/Footer.tsx";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Link from "@mui/material/Link";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Chart from "react-google-charts";


const useStyles = makeStyles((theme) => ({
  gradientPaper: {
    //   background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    backgroundImage:
      "linear-gradient(to right top, #485555, #596468, #6e737a, #838289, #989297, #a6949d, #b6959e, #c5969a, #d1878b, #db777a, #e36668, #e95353)",
    padding: theme.spacing(2),
    zoom:"70%",
    [theme.breakpoints.only("xs")]: {
      zoom: "65%", // adjust zoom for xs only
    },
  },
  gridContainerButtons: {
    [theme.breakpoints.only("xs")]: {
      zoom: "65%", // adjust zoom for xs only
    },
  },
  table: {
    minWidth: 650,
    "& tbody tr:hover": {
      backgroundColor: "#ffa7a7",
    },
  },
  datePicker: {
    [theme.breakpoints.only("xs")]: {
        zoom: "80%", // adjust zoom for xs only
    },
  }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(type: string, no: string) {
  return { type, no };
}

const rows = [
  createData("Visitor", "2032"),
  createData("Resident", "435"),
  createData("Maintainence requests", "2032"),
  createData("Pool maintainence requests", "435"),
];

const Chat = () => {
  const classes = useStyles();
  const [account, setAccount] = React.useState("yearly");

  const [options, setOptions] = React.useState({});
  const [data, setData] = React.useState([]);
  

  const dataQ = [
    ['Parameters', 'No of enteries'],
    ['Visitors Entry', 284],
    ['Residents Entry', 300],
    ['Maintainence Request', 238],
    ['Staff', 30],
    ['No of events', 128],
  ];

  const dataY = [
    ['Parameters', 'No of enteries'],
    ['Visitors Entry', 960],
    ['Residents Entry', 1187],
    ['Maintainence Request', 657],
    ['Staff', 30],
    ['No of events', 312],
  ];

 


  useEffect(() => {

    let role = ["temp"];
    role[0] = localStorage.getItem('userRole');
    if(role[0] === "visitor"){
      window.location.href = '/visitor';
    }else if(role[0] === "resident"){
      window.location.href = '/resident';
    }else if(role[0] === "buildingmanager"){
      window.location.href = '/manager/building';
    }else if(role[0] === "poolmanager"){
      window.location.href = '/manager/pool';
    }else if(role[0] === "gardenmanager"){
      console.log("Hi")
    }else if(role[0] === "securitymanager"){
      window.location.href = '/manager/security';
    }else{
      localStorage.clear();
    }

   if(account=="quarter"){
      setOptions(optionsQuarterly)
      setData(dataQ)
    }else{
      setOptions(optionsYearly)
      setData(dataY)
    }
    
  }, [])

  

  

  const optionsYearly = {
    title: 'Annual Building Reports(On going)',
    pieHole: 0.4,
    backgroundColor: 'transparent',
    is3D: true
  };

  const optionsQuarterly = {
    title: 'Quarter yearly Building Reports(current quarter)',
    pieHole: 0.4,
    backgroundColor: 'transparent',
    is3D: true
  };

  const handleChange = (event: SelectChangeEvent) => {
    if(event.target.value == "yearly"){
      setOptions(optionsYearly)
      setData(dataY)
    }else{
      setOptions(optionsQuarterly)
      setData(dataQ)
    }
    setAccount(event.target.value as string);
  };

  return (
    <div className="home bgimg">
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
        }}
      >
        <ResponsiveAppBar />
      </Box>
      <Box
        class="candles"
        style={{
          backgroundImage: `url(${img1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          color: "#f5f5f5",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          component="span"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            //   backgroundColor: 'rgba(0,0,0,0.4)',
          }}
        />

        <Box
          sx={{
            position: "relative",
            zIndex: 2,
            width: "100%",
            height: "100%",
          }}
        >
          <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
          <Grid container sx={{ m: 2 }} className={classes.gridContainerButtons}>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() => (window.location.href = "/manager/garden/timings")}
                  
                >
                  Manage Timings
                </Button>
              </Grid>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/manager/garden/resident")
                  }
                >
                  Manage Resident
                </Button>
              </Grid>
              <Grid item xs={5} md={2.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/manager/garden/visitor")
                  }
                >
                  Manage Visitor
                </Button>
              </Grid>
              <Grid item xs={5} md={1.5} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/manager/garden/chat")
                  }
                >
                  Chat
                </Button>
              </Grid>

              <Grid item xs={5} md={1} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/manager/garden/report")
                  }
                >
                  Report
                </Button>
              </Grid>


              <Grid item xs={5} md={1} sx={{ m: 1 }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() =>
                    (window.location.href = "/manager/garden/Ads")
                  }
                >
                  Ads
                </Button>
              </Grid>
              
            </Grid>
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
              className={classes.gradientPaper}
            >
              <Typography
                component="h3"
                variant="h5"
                align="center"
                sx={{
                  mb: 2,
                  fontSize: "1.5rem",
                  display: "block",
                  fontFamily: '"Montserrat", sans-serif',
                }}
              >
                <Box sx={{ display: "inline" }}> Building</Box>
                <Box
                  component="span"
                  sx={{
                    color: "red",
                    marginLeft: "0.25rem",
                  }}
                >
                  Reports
                </Box>
              </Typography>
              <Divider variant="middle" />


<Chart
                chartType="PieChart"
                data={data}
                options={options}
                width="100%"
                height="400px"
              />


              {/* <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table
                  sx={{ minWidth: 300 }}
                  aria-label="customized table"
                  className={classes.table}
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Report Type</StyledTableCell>
                      <StyledTableCell align="center">No</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={row.type}>
                        <StyledTableCell component="th" scope="row">
                          {row.type}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          {row.no}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer> */}

              <Divider variant="middle" />

              <form noValidate>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel color="error" id="demo-simple-select-label">
                        Report type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={account}
                        label="Account"
                        color="error"
                        onChange={handleChange}
                      >
                        <MenuItem value={"yearly"}>Yearly</MenuItem>
                        <MenuItem value={"quarter"}>Quarter Yearly</MenuItem>
                        
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* <Grid item xs={12}>
                    <InputLabel>From</InputLabel>
                    
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker name="from" id="from" fullWidth className={classes.datePicker} />
                    </LocalizationProvider>
                  </Grid>


                  <Grid item xs={12}>
                    <InputLabel>To</InputLabel>
                    
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker name="to" id="to" fullWidth className={classes.datePicker} />
                    </LocalizationProvider>
                  </Grid> */}

                  {/* <Grid
                    item
                    xs={12}
                    container
                    justifyContent="center"
                    sx={{ mb: 2 }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="error"
                      className={classes.submitButton}
                    >
                      Submit
                    </Button>
                  </Grid> */}
                </Grid>
              </form>
            </Paper>
          </Container>
        </Box>
      </Box>
      <Footer />
    </div>
  );
};

export default Chat;
