import React, { useState, useEffect } from "react";
import ResponsiveAppBar from "../Components/header2.tsx";
import Box from "@mui/material/Box";
import img1 from "../Assets/img1.jpg";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Footer from "../Components/Footer.tsx";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { LOGIN_URL } from "../APIendpoints.js";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Link from "@mui/material/Link";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  gradientPaper: {
    //   background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    backgroundImage:
      "linear-gradient(to right top, #485555, #596468, #6e737a, #838289, #989297, #a6949d, #b6959e, #c5969a, #d1878b, #db777a, #e36668, #e95353)",
    padding: theme.spacing(2),
  },
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[2],
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: theme.breakpoints.values.sm,
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
  customOutline: {
    borderColor: `#FF0000`,
    "& label.Mui-focused": {
      color: `#FF0000`,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: `#FF0000`,
    },
  },
}));

const Login = () => {

  useEffect(() => {
    let role = ["temp"];
    role[0] = localStorage.getItem('userRole');
    if(role[0] === "visitor"){
      window.location.href = '/visitor';
    }else if(role[0] === "resident"){
      window.location.href = '/resident';
    }else if(role[0] === "buildingmanager"){
      window.location.href = '/manager/building';
    }else if(role[0] === "poolmanager"){
      window.location.href = '/manager/pool';
    }else if(role[0] === "gardenmanager"){
      window.location.href = '/manager/garden';
    }else if(role[0] === "securitymanager"){
      window.location.href = '/manager/security';
    }else{
      localStorage.clear();
    }
  }, []);

  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [open, setOpen] = useState(false);


  const validatePassword = (password) => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    return re.test(password);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleSuccess = (response) => {
    console.log("Success");
    const role = response.register_details.map(detail => detail.role);
    console.log(role)
    const userid = response.register_details.map(detail => detail.userid);
    const email = response.register_details.map(detail => detail.email);
    const address = response.register_details.map(detail => detail.address);
    const contact = response.register_details.map(detail => detail.contact);
    localStorage.setItem('userRole', role[0]);
    localStorage.setItem('userId', userid[0]);
    localStorage.setItem('userEmail', email[0]);
    localStorage.setItem('userAddress', address[0]);
    localStorage.setItem('userContact', contact[0]);

    if(role[0] === "visitor"){
      window.location.href = '/visitor';
    }else if(role[0] === "resident"){
      window.location.href = '/resident';
    }else if(role[0] === "buildingmanager"){
      window.location.href = '/manager/building';
    }else if(role[0] === "poolmanager"){
      window.location.href = '/manager/pool';
    }else if(role[0] === "gardenmanager"){
      window.location.href = '/manager/garden';
    }else if(role[0] === "securitymanager"){
      window.location.href = '/manager/security';
    }else{
      localStorage.removeItem('userRole', role[0]);
      localStorage.removeItem('userId', userid[0]);
      localStorage.removeItem('userEmail', email[0]);
      localStorage.removeItem('userAddress', address[0]);
      localStorage.removeItem('userContact', contact[0]);
      setErrorMessage("Server Error! Please try again");
      setOpen(true)
      setPassword("");
    }
  };

  const handleFailure = (response) => {
    console.log("Failed");
    setErrorMessage("Error Please check credentials and try again");
    setOpen(true)
    setPassword("");
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post(LOGIN_URL, {
        email: email,
        password: password,
      })
      .then(function (response) {
        response.data.status === "success"
          ? handleSuccess(response.data)
          : handleFailure(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="home bgimg">
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
        }}
      >
        <ResponsiveAppBar />
      </Box>
      <Box
        class="candles"
        style={{
          backgroundImage: `url(${img1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          color: "#f5f5f5",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          component="span"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            //   backgroundColor: 'rgba(0,0,0,0.4)',
          }}
        />
        <Box
          sx={{
            position: "relative",
            zIndex: 2,
            width: "100%",
            height: "100%",
          }}
        >
          <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
              className={classes.gradientPaper}
            >
              {/* {errorMessage && (
                <div className="error-message">
                  <p>{errorMessage}</p>
                  <button onClick={() => setErrorMessage(null)}>Cancel</button>
                </div>
              )} */}
              <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
              <Typography
                component="h3"
                variant="h5"
                align="center"
                sx={{
                  mb: 2,
                  fontSize: "1.5rem",
                  display: "block",
                  fontFamily: '"Montserrat", sans-serif',
                }}
              >
                <Box sx={{ display: "inline" }}> Log in to your</Box>
                <Box
                  component="span"
                  sx={{
                    color: "red",
                    marginLeft: "0.25rem",
                  }}
                >
                  resident account!
                </Box>
              </Typography>
              <Divider variant="middle" />

              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      id="email"
                      name="email"
                      label="Email"
                      type="email"
                      color="error"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      error={email != "" && !validateEmail(email)}
                      helperText={
                        email != "" && !validateEmail(email)
                          ? "Please enter a valid email address"
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      id="contact"
                      color="error"
                      name="contact"
                      label="Password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      // error={!validatePassword(password)}
                      // helperText={password!="" && !validatePassword(password) ? 'Password should contain at least one uppercase letter, one lowercase letter, one number, and be at least 8 characters long' : ''}
                    />
                  </Grid>

                  <Grid item xs={12} container justifyContent="center">
                    <Button
                      type="submit"
                      variant="contained"
                      color="error"
                      className={classes.submitButton}
                      disabled={!validateEmail(email)}
                    >
                      Submit
                    </Button>
                  </Grid>
                  <Grid item xs={12} container justifyContent="center">
                    <Link href="/forgotpassword" color="primary">
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item xs={12} container justifyContent="center">
                    <Link href="/signup" color="primary">
                      Don't have an account? Sign up
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Container>
        </Box>
      </Box>
      <Footer />
    </div>
  );
};

export default Login;
